import axios from 'axios';


// function getDate(timeZone = 'local'){
//     const date = new Date;
//     const dateOptions = {
//         year: 'numeric',
//         month: 'numeric',
//         day: 'numeric',
//
//     }
//     const timeOptions ={
//         timeZone: timeZone === 'local' ? Intl.DateTimeFormat().resolvedOptions().timeZone : timeZone,
//         hour: 'numeric',
//         minute: 'numeric',
//         second: 'numeric',
//         hourCycle: 'h24'
//     }
//     let formattedDate = new Intl.DateTimeFormat('en-ca', dateOptions).format(date)
//     let formattedTime = new Intl.DateTimeFormat('en-ca', timeOptions).format(date)
//     return `${formattedDate}T${formattedTime}`
// }


let subdomain = window.location.hostname.split('.')[0]
console.log(subdomain)
let appTypeUrl = null

switch (subdomain) {
    case 'test-auto':
    case 'test-cash':
    case 'test-nordwind':
    case 'test-vtb':
    case 'test-travelata':
    case 'test-bankiros':
    case 'test-southwind':
        appTypeUrl = 'https://test-card.paylate.ru/'
        break;
    case 'auto':
    case 'cash':
    case 'nordwind':
    case 'vtb':
    case 'bankiros':
    case 'travelata':
    case 'southwind':
        appTypeUrl = 'https://card.paylate.ru/'
        break;
    default:
        appTypeUrl = 'https://test-card.paylate.ru/'
        break;
}

function proxyConnectorToken(url, data) {
    let config = {
        method: 'POST',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        url: appTypeUrl + url,
        data : data
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {resolve(response.data);})
            .catch(err => reject(err));
    });
}

function dadata(url, query, parts) {
    var dadataUrl = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/";
    var token = "8ea87af5d3bb2c80b6f051d1c6c153f26d65eef4";

    var options = {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Token " + token
        },
        body: JSON.stringify({query, parts})
    }

    return fetch(dadataUrl + url, options)
        .then(response => response.json());
    // .then(result => console.log(result))
    // .catch(error => console.log("error", error));
}


/*function roundDot2(number) {
    return Math.round(number * 100) / 100;
}*/

export default {
    data() {
        return {
            connector: {
                isMainInformationCalc: (get_param) => {
                    const token = '';
                    const data = {
                        s: get_param
                    };
                    return this.proxyConnector('cash/get_params', data, token);
                },
                getTokenAccessAndRefresh: (phone, password) => {
                    let data = new FormData();
                    data.append('phone', phone);
                    data.append('password', password);
                    return proxyConnectorToken('api/v1/get_token/', data);
                },
                // getTokenAccessAndRefreshWithBirthday:(phone, birthday) => {
                //     let data = new FormData();
                //     data.append('phone', phone);
                //     data.append('birthday', birthday);
                //     return proxyConnectorToken('api/v1/get_token/', data);
                // },
                getTokenAccess: () => {
					if (this.anketa.new_client_token) {
						return new Promise((resolve) => {
							resolve({success: true})
						});
					}else {
						let data = new FormData();
						data.append("refresh", localStorage.getItem('tokenRefresh'));
						return proxyConnectorToken('api/v1/token/refresh/', data);
					}
                },
                clientExists: (phone) => {
                    return this.proxyConnector('cash/exists/', {phone});
                },
                sopdSend: (phone) => {
                    return this.proxyConnector('cash/sopd_send/', {phone});
                },
                sopdSendWithBirthday: (phone, birthday, shopId) => {
                    return this.proxyConnector('cash/sopd_send/', {phone, birthday, shopId});
                },
                sopdVerify: (code, phone, shopId) => {
                    return this.proxyConnector('cash/sopd_verify/', {code, phone, shopId});
                },
                isRegistrationAndCreateApplication: (data) => {
                    data.shop_id = this.anketa?.conditions?.shopId;
                    // if(data.juicyData){
                    //     const juicyData = {
                    //         TimeZone: (0 - new Date().getTimezoneOffset()) / 60,
                    //         UserAgent: navigator.userAgent,
                    //         // Cookie: this.juicySession,
                    //         TimeLocal: getDate(),
                    //         TimeMsk: getDate('Europe/Moscow'),
                    //         IsJavascriptEnabled: 1,
                    //         Channel: "SITE",
                    //     };
                    //     Object.assign(data.juicyData, juicyData);
                    // }
                    if(this.anketa.juicyData){

                        // Object.assign(data.juicyData, this.anketa.juicyData);
                        data.juicyData = this.anketa.juicyData;
                    }
                    // data.juicyData = juicyData
                    return this.proxyConnector('cash/create/', data);
                },
                saveDocuments: (data) => {
                    const token = localStorage.getItem('token');
                    const token_dt = localStorage.getItem('token_dt')
                    const phone = this.anketa.phone.valueFirstDigitIs9()
                    data.token = token
                    data.token_dt = token_dt
                    data.phone = phone
                    return this.proxyConnector('cash/enrichment/', data, token, token_dt, phone)
                },
                checkPassportIssuer: () => {
                    const data = {
                        divisionCode: this.anketa.divisionCode.cleanValue(),
                        issueDate: this.anketa.issueDate.value,
                    };

                    return new Promise( (resolve,reject) => {
                        axios.post('https://cpoint.ru/Services/AutoComplete/api/Main/PassportIssuer', data)
                            .then( response => resolve(response.data) )
                            .catch( err => reject(err) );
                    });
                },
                isAuthAndCreateApplication: (data) => {
                    data.shop_id = this.anketa?.conditions?.shopId;
                    // if(data.juicyData){
                    //     const juicyData = {
                    //         TimeZone: (0 - new Date().getTimezoneOffset()) / 60,
                    //         UserAgent: navigator.userAgent,
                    //         // Cookie: this.juicySession,
                    //         TimeLocal: getDate(),
                    //         TimeMsk: getDate('Europe/Moscow'),
                    //         IsJavascriptEnabled: 1,
                    //         Channel: "SITE",
                    //     };
                    //     Object.assign(data.juicyData, juicyData);
                    // }

                    if(this.anketa.juicyData){

                        // Object.assign(data.juicyData, this.anketa.juicyData);
                        data.juicyData = this.anketa.juicyData;
                    }

                    // data.juicyData = juicyData
                    return this.proxyConnector('cash/create/', data);
                },
                isRecoveryPassword: (phone, date) => {
                    console.log(phone)
                    const data = {
                        phone : phone,
                        birth_date: date
                    };

                    return this.proxyConnector('cash/drop_pass/', data);
                },
                isStatusApplication: () => {
                    const token = (localStorage.getItem('token'))? localStorage.getItem('token'):'';
                    const token_dt = (localStorage.getItem('token_dt'))? localStorage.getItem('token_dt'):'';
                    const phone = this.anketa.phone.valueFirstDigitIs9()
                    const dataBody = {
                        token,
                        token_dt,
                        phone
                    }

                    console.log('getAuthAndCreateApplication');
                    return this.proxyConnector('cash/status/', dataBody);
                },
                isInsurancePackage: (id) => {
                    const token = (localStorage.getItem('token')) ? localStorage.getItem('token'):'';
                    const token_dt = (localStorage.getItem('token_dt')) ? localStorage.getItem('token_dt'):'';
                    const data = {
                        token,
                        token_dt,
                        phone: this.anketa.phone.valueFirstDigitIs9(),
                        set: id
                    };
                    this.$root.mindbox("Управление доп.услугами Кэш");
                    return this.proxyConnector('cash/pick_options/', data);

                },
                isRequestForSMSCode: () => {
                    const token = (localStorage.getItem('token')) ? localStorage.getItem('token'):'';
                    const token_dt = (localStorage.getItem('token_dt')) ? localStorage.getItem('token_dt'):'';
                    const phone = this.anketa.phone.valueFirstDigitIs9()
                    const data = {
                        token,
                        token_dt,
                        phone
                    };
                    console.log('isRequestForSMSCode');

                    return this.proxyConnector('cash/send_sign_sms/', data);

                },
                isSingApplication: (code) => {
                    const token = (localStorage.getItem('token')) ? localStorage.getItem('token'):'';
                    const token_dt = (localStorage.getItem('token_dt')) ? localStorage.getItem('token_dt'):'';
                    const phone = this.anketa.phone.valueFirstDigitIs9()

                    //temp
                    const data = {
                        token,
                        token_dt,
                        phone,
                        signCode: code
                    };

                    // const applicationId = this.anketa.applicationId.value
                    // const data = {
                    //     "Url": "http://192.168.20.74/paylateservice/api/posCredit/v3/sign",
                    //     "Data": {
                    //       "applicationId": '1167309',
                    //       "signCode": code
                    //     }
                    // };
                    return this.proxyConnector('cash/sign/', data);
                    //temp
                    // return this.proxyConnectorWidget('cash/sign/', data);

                },
                getDadataAddress(query) {
                    return dadata('suggest/address', query);
                },
                getDadataParty(query) {
                    return dadata('suggest/party', query);
                },
                getDadataFio(query, parts = []) {
                    return dadata('suggest/fio', query, parts);
                },
                getDadataBankId(query) {
                    return dadata('suggest/bank', query);
                },

                loanUpdate: (order = {}) => {
                    const token = (localStorage.getItem('token'))? localStorage.getItem('token'):'';
                    const token_dt = (localStorage.getItem('token_dt'))? localStorage.getItem('token_dt'):'';
                    const phone = this.anketa.phone.valueFirstDigitIs9()
                    const dataBody = {
                        token,
                        token_dt,
                        phone,
                        order
                    }
                    console.log('loan_update');
                    console.log(order)
                    return this.proxyConnector('cash/loan_update/', dataBody);
                },

                getBanks:(date) =>{
                    const data = {
                        phone: this.anketa.phone.valueFirstDigitIs9(),
                        DateTime: date
                    }
                    let url = 'cash/get_banks/'
                    let config = {
                        method: 'GET',
                        url: appTypeUrl + url,
                        headers: {
                            'Content-Type':'application/json',
                            // 'Access-Control-Allow-Origin': '*'
                        },
                        data: data,
                        date: date
                    };

                    return new Promise((resolve, reject) => {
                        axios(config)
                            .then(response => resolve(response))
                            .catch(err => reject(err));
                    });
                },

                // getBanksFromSbpApi:() => {
                //     let config = {
                //         method: 'GET',
                //         url: 'https://qr.nspk.ru/proxyapp/c2bmembers.json',
                //         headers: {
                //             'Content-Type':'application/json',
                //         },
                //     };
                //
                //     return new Promise((resolve, reject) => {
                //         axios(config)
                //             .then(response => resolve(response.data))
                //             .catch(err => reject(err));
                //     });
                // },

                promoVerify:(promocode, phone) => {
                    const token = (localStorage.getItem('token'))? localStorage.getItem('token'):'';
                    const token_dt = (localStorage.getItem('token_dt'))? localStorage.getItem('token_dt'):'';
                    // const phone = this.anketa.phone.valueFirstDigitIs9()
                    const data = {
                        token,
                        token_dt,
                        phone,
                        promo_code: promocode
                    }
                    return this.proxyConnector('cash/promo_verify/', data);
                },

                promoSave:(promocode, phone) => {
                    const token = (localStorage.getItem('token'))? localStorage.getItem('token'):'';
                    const token_dt = (localStorage.getItem('token_dt'))? localStorage.getItem('token_dt'):'';
                    // const phone = this.anketa.phone.valueFirstDigitIs9()
                    const data = {
                        token,
                        token_dt,
                        phone,
                        promo_code: promocode
                    }
                    return this.proxyConnector('cash/promo_save/', data);
                },
                startProccess:(type, proccessData, method, timeout) =>{
                    let data = {}
                    let preccessIdentifiers = {
                        integration: "IntegrationApiCash_process_1hdzd8y",
                        esia1: "saveSession_Process_0jx7u67",
                        esia2: "saveSession_Process_0jx7u67",
                        esia3: "getLinkEsia_Process_1cylam1",
                        esia4: "getSessionInfo_0w5zb0g",
                        esia5: "esiaClientData_Process_0ds7pez",
                        // esia5: "esiaClientData_Process",//не правильный, для тестов
                        esia6: "saveSession_Process_0jx7u67",
                    }

                    for(const proccessId in preccessIdentifiers){
                        if(proccessId === type){
                            data = {
                                processId: preccessIdentifiers[proccessId],
                                isNeedResult: true,
                                variables: proccessData
                            }
                        }
                    }
                    return this.comundaConnector("/startProcess", data, "POST", timeout)
                },

                integrationApiCash:(appId) =>{
                    let getParams = location.search.split('&');
                    let utmSource = getParams.find(param => param.includes('utm_source'))?.split('=')[1];
                    console.log('utmSource', utmSource);
                    let createDataObject = (params) =>{
                        if(Object.keys(getParams).length !== 0){
                            console.log('connector this', this)
                            let tokenDt = localStorage.getItem('token_dt').split(' ')
                            tokenDt = `${tokenDt[0]}T${tokenDt[1]}`

                            const lidgidData = {
                                appId: Number(appId),
                                tokenDt: tokenDt,
                                token: localStorage.getItem('token'),
                                phone: this.anketa.phone.valueFirstDigitIs9()
                            }
                            Object.assign(lidgidData, params)
                            this.connector.startProccess('integration', lidgidData)
                        }
                    }
                    if(utmSource === "lg"){
                        const lidGidParams = ['wmid', 'id', 'utm_medium', 'utm_referrer', 'utm_source']
                        getParams = getParams.map(param => param.split('='))
                        getParams = Object.fromEntries(getParams)
                        for(let i in getParams) {
                            if(!lidGidParams.includes(i)) {
                                delete getParams[i]
                            }
                        }
                        let params = {
                            trafficSource: 'Lg',
                            medium: getParams.utm_medium,
                            source: getParams.utm_source,
                            affiliateId: getParams.wmid,
                            transactionId: getParams.id,
                        }
                        createDataObject(params)
                    }
                    else if(utmSource === "mkb"){
                        let clickId = getParams.find(param => param.includes('clickid'))
                        if(clickId){
                            let params = {
                                trafficSource: utmSource,
                                source: utmSource,
                                transactionId: clickId?.split('=')[1],
                                affiliateId: "1"
                            }
                            createDataObject(params)
                        }
                    }else if(utmSource === "sravni") {
                        let transactionId = getParams.find(param => param.includes('transaction_id'))
                        let params = {
                            trafficSource: utmSource,
                            source: utmSource,
                            transactionId: transactionId?.split('=')[1],
                            affiliateId: "1"
                        }
                        createDataObject(params)
                    }else if(utmSource === "cityads"){
                        let clickId = getParams.find(param => param.includes('click_id'))
                        if(clickId){
                            let params = {
                                trafficSource: utmSource,
                                source: utmSource,
                                transactionId: clickId?.split('=')[1],
                                affiliateId: "1"
                            }
                            createDataObject(params)
                        }
                    }
                },

                getUserInfo: () => {
                    let baseUrl = null
                    const subdomainText = subdomain.split('-')[0]
                    if(subdomainText === 'test' || subdomainText === 'localhost'){
                        baseUrl = 'https://test-c.paylate.ru'
                    }else if(subdomainText !== 'test'){
                        baseUrl = 'https://c.paylate.ru'
                    }
                    let config = {
                        method: "GET",
                        headers: {
                            'Content-Type':'application/json'
                        },
                        url: `${baseUrl}/getIpInfo`,
                        timeout: 0,
                    };

                    return new Promise((resolve, reject) => {
                        axios(config)
                            .then(response => resolve(response.data))
                            .catch(err => reject(err));
                    });

                    // const config ={
                    //     method: 'GET',
                    //     url: 'https://datacollect-test.2pp.dev/ip.php',
                    //     headers: {
                    //         'Content-Type':'application/json'
                    //     },
                    // }
                    //
                    // return new Promise((resolve, reject) => {
                    //     axios(config)
                    //         .then(response => resolve(response.data))
                    //         .catch(err => reject(err));
                    // });
                }
            }
        }
    },

    methods: {
        proxyConnector(url, data, token, client = {}) {
            let config = {
                method: 'POST',
                url: appTypeUrl + url,
                headers: {
                    'Content-Type':'application/json'
                },
            };

            console.log(this.anketa.new_client_token);

            if (this.anketa.new_client_token || Object.keys(client).length) {
                data.client_id = this.anketa.client_id || client.client_id;
                data.new_client_token = this.anketa.new_client_token || client.new_client_token;
                data.token_timestamp = this.anketa.token_timestamp || client.token_timestamp;
            } else if(!this.anketa.new_client_token || !client) {
                config.headers.Authorization = token;
            }

            config.data = data;

            return new Promise((resolve, reject) => {
                axios(config)
                    .then(response => resolve(response.data))
                    .catch(err => reject(err));
            });
        },

        comundaConnector(url, data, method="POST", timeout) {
            // PROD: https://c.paylate.ru/startProcess
            // TEST: https://test-c.paylate.ru/startProcess
            let baseUrl = null
            const subdomainText = subdomain.split('-')[0]
            if(subdomainText === 'test' || subdomainText === 'localhost'){
                baseUrl = 'https://test-c.paylate.ru'
            }else if(subdomainText !== 'test'){
                baseUrl = 'https://c.paylate.ru'
            }
            let config = {
                method: method,
                headers: {
                    'Content-Type':'application/json'
                },
                url: baseUrl + url,
                data : data,
                //время до прерывания запроса, 0 - означает без времени ожидания
                timeout: timeout ? timeout : 0,
            };

            return new Promise((resolve, reject) => {
                axios(config)
                    .then(response => {resolve(response.data);})
                    .catch(err => reject(err));
            });
        }

        // proxyConnectorWidget(url, data, token, client = {}) {
        //     let config = {
        //         method: 'POST',
        //         // url: 'https://card.paylate.ru/' + url, // prod
        //         // url: 'https://test-card.paylate.ru/' + url, // test
        //         url: 'https://test-widget.paylate.ru/api/paylate/push',
        //         headers: {
        //             'Content-Type':'application/json'
        //         },
        //     };

        //     console.log(this.anketa.new_client_token);

        //     if (this.anketa.new_client_token || Object.keys(client).length) {
        //         data.client_id = this.anketa.client_id || client.client_id;
        //         data.new_client_token = this.anketa.new_client_token || client.new_client_token;
        //         data.token_timestamp = this.anketa.token_timestamp || client.token_timestamp;
        //     } else if(!this.anketa.new_client_token || !client) {
        //         config.headers.Authorization = token;
        //     }

        //     config.data = data;

        //     return new Promise((resolve, reject) => {
        //         axios(config)
        //             .then(response => resolve(response.data))
        //             .catch(err => reject(err));
        //     });
        // },
    }
}
