import { render, staticRenderFns } from "./SouthwindTemplateSlider.vue?vue&type=template&id=0532e610&scoped=true"
import script from "./SouthwindTemplateSlider.vue?vue&type=script&lang=js"
export * from "./SouthwindTemplateSlider.vue?vue&type=script&lang=js"
import style0 from "./SouthwindTemplateSlider.vue?vue&type=style&index=0&id=0532e610&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0532e610",
  null
  
)

export default component.exports