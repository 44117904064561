<template>
    <div class="toggle-button">
        <p class="toggle-button_text mb-0">Согласен</p>
        <div class="toggle-button_container">

            <input
                v-model="fieldValue"
                type="checkbox"
                class="toggle-button_container_checkbox"
            />
            <div

                class="toggle-button_switch "
            >
            </div>
        </div>
        <p class="toggle-button_text mb-0">Не согласен</p>
    </div>
</template>

<script>
export default {
    props: ['value'],
    data(){
        return {
        }
    },
    computed: {
        anketa(){
            return this.$root.anketa
        },
        fieldValue: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val, this.value);
            }
        }
    }
}

</script>

<style lang="scss" scoped>
.toggle-button {
    display: flex;
    align-items: center;
    gap: 10px;
    max-width: 180px;
    width: 100%;
    // justify-content: space-between;
    &_text {
        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.3px;
        // color: #000;
        color: rgba(0, 0, 0, 0.34);
        // opacity: 0.34%;

    }
    &_container {
        width: 28px;
        height: 14px;
        border-radius: 36px;
        background-color:#E9E9E999;
        cursor: pointer;
        position: relative;

        &_checkbox {
            position: relative;
            z-index: 100;
            width: 30px;
            height: 20px !important;
            // visibility: hidden;
            opacity: 0;
            cursor: pointer;
            margin-top: 0 !important;
        }

    }
    &_switch{
        position: absolute;
        top: 50%;
        transform: translate(100%, -50%);
        left: 0;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background-color: #FDFDFE;
        transition: all .1s ease-in;
        box-shadow: 0px 2px 4px -1px #0A0A0D26;
    }

}
// .move-right {
//     transform: translateX(100%);
// }

input[type="checkbox"]:checked + .toggle-button_switch {
    top: 50%;
    transform: translate(0, -50%);
}
</style>
