<template>
    <div>
        <transition-group name="show-fade" mode="out-in">
            <Phone
                v-if="router.phone.status"
                key="phone"
                @changeTemplate="goNextStep"
            />
            <Account
                v-if="router.account.status"
                key="account"
                @changeTemplate="goNextStep"
            />
            <Auth
                v-if="router.auth.status"
                key="auth"
                @changeTemplate="goNextStep"
            />
            <Registration
                v-if="router.registration.status"
                key="registration"
                @changeTemplate="goNextStep"
            />
            <RecoveryPass
                v-if="router.recoveryPass.status"
                key="recoveryPass"
                @changeTemplate="goNextStep"
            />
            <CheckApplication
                v-if="router.checkApplication.status"
                key="checkApplication"
                @changeTemplate="goNextStep"
            />
            <Back
                v-if="router.back.status"
                key="back"
                :prevRoute="currentRoute"
                @changeTemplate="goNextStep"
            />
            <StatusWaiting
                v-if="router.statusWait.status"
                :merge="merge"
                :router="router"
                key="statusWait"
                :waitingData="waitingData"
                @changeTemplate="changeStatusAndTemplate"
            />
            <NeedMoreData
                v-if="router.needMoreData.status"
                key="needMoreData"
                @changeTemplate="changeStatusAndTemplate"
            />
            <AccessApplication
                v-if="router.accessApplication.status"
                key="accessApplication"
                @changeTemplate="changeStatusAndTemplate"
            />
            <SmsPage
                v-if="router.smsPage.status"
                key="smsPage"
            />
            <Declined
                v-if="router.declined.status"
                key="declined"
            />
            <WaitingEsia
                v-if="router.waitingEsia.status"
                key="waitingEsia"
                @changeTemplate="goNextStep"
            />
        </transition-group>
    </div>
</template>

<script>
import Phone from "@/components/modal/Phone";
import Auth from "@/components/modal/Auth";
import Account from "@/components/modal/Account";
import StatusWaiting from "@/components/modal/StatusWaiting";
import RecoveryPass from "@/components/modal/RecoveryPass";
import NeedMoreData from "@/components/modal/NeedMoreData";
import AccessApplication from "@/components/modal/AccessApplication";
import SmsPage from "@/components/modal/SmsPage";
import Back from "@/components/modal/Back.vue";
import Declined from "@/components/modal/Declined";
import CheckApplication from "@/components/modal/CheckApplication.vue";
import Registration from "@/components/modal/registration/Registration.vue";
import WaitingEsia from "@/components/modal/WaitingEsia.vue";

export default {
    name: "Modals",

    props: {
        router: {
            type: Object,
        },
        step: {
            type: Number,
        }
    },

    components: {
        WaitingEsia,
        Phone,
        Declined,
        AccessApplication,
        NeedMoreData,
        RecoveryPass,
        Account,
        Registration,
        Auth,
        StatusWaiting,
        SmsPage,
        Back,
        CheckApplication,
    },

    data: () => ({
        currentRoute: "",
        waitingData: {},
        merge: false,
    }),

    computed: {
        anketa() {
            return this.$root.anketa;
        },

    },

    watch: {
        templateAccount: function () {
            if (
                this.router.account.status ||
                this.router.auth.status ||
                this.router.recoveryPass.status
            ) {
                this.changeRouter("account", "account");
            } else {
                this.changeRouter(this.currentRoute, "back");
            }
        },
    },

    methods: {
        goNextStep(data) {
            // console.log('go next step', data)
            this.currentRoute = data.next;
            // console.log('currentRoute', this.currentRoute)
            if (data.merge) {
                this.merge = data.merge;
            }
            if (data.success) {
                console.log('success', data)
                this.changeRouter(data.prev, data.next);
            }
        },

        changeStatusAndTemplate(route, data) {
            this.currentRoute = route.nextPage;
            this.waitingData = data;

            for (const k in this.router) {
                if (k === route.nextPage) {
                    this.changeRouter(route.prevPage, route.nextPage);
                }
            }
        },

        changeRouter(prev, next) {
            if (prev.length != 0 && next.length != 0) {
                // if (this.resultStatus !== {}) {
                //   this.resultStatus?.clearInterval();
                // }
                // console.log('modals step', this.step)
                // console.log('modals changeRouter', prev, next)
                if(this.step) {
                    if (this.$root.state.esiaStatus.usingStep) {
                        // console.log('steeeeeeeeeeeeeeeeeeeep2')
                        for (const k in this.router) {
                            this.router[k].status = false
                            // console.log('this.router[k]', this.router[k]);
                            if (this.router[k].step === this.step) {
                                this.router[k].status = true
                            }
                            // if(this.router[k].status !== undefined && typeof(this.router[k]) !== 'string') {
                            //     this.router[k].status = false;
                            //     console.log('k', k)
                            //     if(!this.step){
                            //         console.log("step", this.step)
                            //         if(this.step === this.router[k].step){
                            //             this.router[k].status = true;
                            //         }
                            //     }else {
                            //         this.router[next].status = true;
                            //     }
                            //
                            // }else if(typeof(this.router[k]) === 'string'){
                            //     this.router.history = prev;
                            // }
                        }
                    } else {
                        // console.log('steeeeeeeeeeeeeeeeeeeep3')

                        for (const k in this.router) {
                            // console.log('router', `${k} - ${this.router[k].status}`);
                            if(k !== 'history'){
                                this.router[k].status = false;
                            }

                        }

                        this.router[next].status = true;
                        this.router.history = prev;

                    }


                }else {
                    for (const r in this.router) {
                        if(r !== 'history') {
                            this.router[r].status = false;
                        }

                        // this.router[prev].status = false;
                    }
                        this.router[prev].status = false
                        this.router.history = prev;
                        this.router[next].status = true;
                }


            } else {
                return;
            }
        },

        // changeRouter(prev, next) {
        //     if (prev.length != 0 && next.length != 0) {
        //         // if (this.resultStatus !== {}) {
        //         //   this.resultStatus?.clearInterval();
        //         // }
        //
        //         for (const k in this.router) {
        //             this.router[k].status = false;
        //         }
        //         this.router[next].status = true;
        //         this.router.history = prev;
        //     } else {
        //         return;
        //     }
        // },


        mounted(){
            console.log('modals router', this.router);
        }

        // chooseRoutingLogic(){
        //     console.log('modals step',this.step)
        //     if(this.step) {
        //         // console.log('stepeeeeeeeeeeeee', this.step)
        //         if(this.step) {
        //             for (let r in this.router) {
        //                 console.log(r)
        //             }
        //         }
        //     }
        // }
    },
};
</script>
