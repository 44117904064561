<template>
    <div class="esia-panel">
        <div
            v-if="step === 2"
            class="esia-panel_info"
            >
            <p class="esia-panel_info_text">
                Авторизуйтесь через портал госуслуг -  анкета будет заполнена автоматически.
<!--                {{textByType}}-->
            </p>

        </div>
        <div
            v-if="step === 3"
            class="esia-panel_info"
            >
            <MarkInfo
                class="esia-panel_info_mark"
                type='exclamation'
            />

            <p class="esia-panel_info_text">
                Нам нужно ещё немного информации.<br> Пожалуйста, прикрепите необходимые документы или авторизуйтесь через госуслуги.
            </p>
        </div>
        <div class="esia-panel_btn_container">
            <v-btn
                @click="callEsia"
                :loading="loading"
                class="esia-panel_btn"
                >
                <img src="@/assets/img/gosuslugi_btn_img.svg" alt="логотип госсуслуг" class="esia-panel_btn_img">
                <span class="esia-panel_btn_text">+20% к одобрению</span>
            </v-btn>
            <p v-if="isEsiaHasError" class="esia-panel_error">Ошибка авторизации. Загрузите данные вручную</p>
        </div>
    </div>
</template>

<script>
import MarkInfo from "@/components/controls/MarkInfo.vue";


import moment from "moment-timezone";

export default {
    name: "EsiaPanel",
    components: {MarkInfo},
    props: {
      step: Number
    },
    data(){
        return {
            landingsTexts: {
                cash: 'Авторизуйтесь через портал госуслуг -  анкета будет заполнена автоматически.',
                nordwind: '',
                vtb: '',
                travelata: '',
                bankiros: '',
                southwind: '',
                auto: ''

            },
            errorInfo: {
                status: false,
                message: ''
            },
            partnerNumber: null,
            listStocks: [],
            loading: false
        }
    },

    computed: {
        connector(){
            return this.$root.connector;
        },
        anketa(){
            return this.$root.anketa;
        },
        textByType(){
            const type = this.$root.state.type();
            let text = null
            for(let [key, value] of Object.entries(this.landingsTexts)){
                 if(key === type){
                     text = value
                 }
            }
            return text
        },

        getRates(){
            let sliderTermValue = this.anketa.term.valueTerm
            let stock = this.listStocks.find(stock => stock.period === sliderTermValue)
            return stock
        },
        changeDirectionByWidth(){
            let direction = null;
            this.$root.$vuetify.breakpoint.smAndDown ? direction = 'column' : 'row'
            return direction
        },
        getEsiaStep(){
            return this.$root.state.esiaStatus.step
        },
        isEsiaHasError(){
            return this.$root.state.esiaStatus.hasError.esiaClientData
        }
    },

    methods: {
        callEsia(){
            this.$root.mindbox("Авторизовался на ГОСУСЛУГАХ Кэш");
            this.loading = true;
            let urlParams = new URLSearchParams(window.location.search);
            this.connector.isMainInformationCalc(urlParams.get("s"))
                .then((response) => {
                    if(response.success){
                        this.listStocks = response.slider_params.listStocks;
                        // const juicyData = {
                        //     IPAddress: ''
                        // }
                        // this.connector.getUserInfo()
                        //     .then((response) => {
                        //         if(response){
                        //             juicyData.IPAddress = response?.ip
                        //
                        //             Object.assign(juicyData,{
                        //                 TimeZone: (0 - new Date().getTimezoneOffset()) / 60,
                        //                 UserAgent: navigator.userAgent,
                        //                 Cookie: this.$root.juicySession,
                        //                 TimeLocal: moment().format('YYYY-MM-DDTHH:mm:ss'),
                        //                 TimeMsk: moment.tz('Europe/Moscow').format('YYYY-MM-DDTHH:mm:ss'),
                        //                 IsJavascriptEnabled: 1,
                        //                 Channel: "SITE",
                        //             })
                        //             console.log('getRates', this.getRates)
                                    let rates = {
                                        rateId: this.getRates.rateId,
                                        percent: this.getRates.percent,
                                        period: this.getRates.period,
                                    }
                                    const esiaData = {
                                        sessionId: localStorage.getItem('sessionId'),
                                        token: localStorage.getItem('comundaToken'),
                                        tokenDt: localStorage.getItem('comundaTokenDt'),
                                        step: this.step,
                                        // step: 3,
                                        applicationId: null,
                                        anketInfo: {
                                            partnerNumber: this.getRates.shopId,
                                            phoneNumber: this.anketa.phone.valueFirstDigitIs9(),
                                            flagEsia: true,
                                            juicyData: this.anketa.juicyData,
                                            rates: [rates],
                                            requestedCreditAmount: this.anketa.initialPayment.value
                                        },

                                    }

                                    this.connector.startProccess('esia2', esiaData)

                                        .then((response) => {
                                            if(response.resultProcess.success){
                                                let getUrl = location.href;
                                                if(getUrl.includes("appClientId")){
                                                    let exeptions = ["appClientId", "result", "appId"]
                                                    let urlArray = getUrl.split('&')
                                                    exeptions.forEach(exeption => {
                                                        urlArray = urlArray.filter(el=> !el.includes(exeption));
                                                    })
                                                    getUrl = urlArray.join('&')
                                                }

                                                let esiaData = {
                                                    sessionId: localStorage.getItem('sessionId'),
                                                    appCallBack: getUrl,
                                                    // tokenDt: localStorage.getItem('comundaTokenDt'),
                                                    tokenDt: moment(localStorage.getItem('comundaTokenDt')).format('YYYY-MM-DDTHH:mm:ss'),
                                                    token: localStorage.getItem('comundaToken'),
                                                    phone: this.anketa.phone.valueFirstDigitIs9()
                                                }
                                                console.log('esiaData3', esiaData.appCallBack)
                                                this.connector.startProccess('esia3', esiaData)
                                                    .then((response) => {
                                                        if(response.resultProcess.value){
                                                            location.replace(response.resultProcess.value)
                                                        }
                                                    })
                                                    .catch((error) => {
                                                        console.log(error)
                                                        this.errorInfo.status = true
                                                        this.errorInfo.message = error.message;
                                                        this.loading = false;
                                                    })
                                            }else {
                                                this.errorInfo.status = true
                                                this.errorInfo.message = response.message;
                                                this.loading = false;
                                            }

                                        })
                                        .catch((error) => {
                                            console.log(error)
                                            this.errorInfo.status = true
                                            this.errorInfo.message = error.message;
                                            this.loading = false;
                                        })
                                // }else {
                                //     this.errorInfo.status = true
                                //     // this.errorInfo.message = response.message;
                                //     this.errorInfo.message = "ошибка";
                                // }


                            // })
                            // .catch((error) => {
                            //     console.log(error)
                            //     this.errorInfo.status = true
                            //     this.errorInfo.message = error.message;
                            // })
                    }else {
                        this.errorInfo.status = true
                        this.errorInfo.message = 'isMainInformationCalc вернул ошибку';
                        this.loading = false;
                    }
                })
                .catch((error) => {
                    console.log(error)
                    this.errorInfo.status = true
                    this.errorInfo.message = error.message;
                    this.loading = false;
                })

        }
    },

}
</script>


<style scoped lang="scss">
    .esia-panel {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        align-items: center;
        flex-direction: v-bind(changeDirectionByWidth);
        margin-bottom: 40px;

        &_info {
            display: flex;
            gap: 8px;
            position: relative;

            &_mark {
                position: relative;
                top: 2px;
                left: 0;
            }

            &_text {
                font-family: Roboto;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.4px;
                color: #0A0A0D;
                text-align: left;
                margin-bottom: 0 !important;
            }
        }

        &_btn {
            border: 2px solid #EB3B66;
            border-radius: 14px;

            width: 100%;
            height: 52px !important;
            display: flex;
            justify-content: center;
            gap: 10px;
            align-items: center;
            background-color: #fff !important;

            &_container {
                max-width: 268px;
                width: 100%;
                position: relative;
            }
            &_text {
                font-family: Roboto;
                font-size: 14px;
                font-weight: 600;
                line-height: 16px;
                letter-spacing: -0.4px;
                color: #EB3B66;
            }
        }
        &_error {
            color: red;
            font-size: 14px;
            font-family: Roboto;
            font-weight: 400;
            position: absolute;
            margin: 0 !important;
            top: 57px;
            right: 0;
        }
    }
</style>
