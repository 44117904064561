<template>
    <div class="file-field">
        <div v-if="!$root.appType('travelata')" class="file-label">{{ label }}</div>
        <div v-if="$root.appType('travelata')" class="travelata_file-label">{{ label }}</div>
        <div class="modal-screen-input">
            <div
                :class="containerStates"
                class="file-container"
                :id="img"
                @click="$refs.file.click()"
                @dragover.prevent
                @drop.stop.prevent="onDrop"
                @dragenter.stop.prevent="isDragOver = true"
                @dragleave.stop.prevent="isDragOver = false"
                >
                <v-text-field
                    autocomplete="false"
                    solo
                    flat
                    :value="fileName"
                    :hide-details="false"
                    :rules="rules"
                    class="input-wrapper"
                    >
                </v-text-field>
                <input
                    nokey="updateField"
                    @change="upload($refs.file)"
                    ref="file"
                    type="file"
                    class="file-input"
                    :accept="accept"
                    style="display: none"
                />
                <v-fade-transition>
                    <div class="file-info1">
                        <div v-if="!fileName">
                            <div :class="!$root.appType('travelata') ? 'file-placeholder' : 'travelata_file-placeholder'">
                                <span>{{ placeholder }}</span>
                                <v-icon color="#848485">mdi-plus-circle</v-icon>
                            </div>
                            <v-fade-transition>
                                <div
                                    v-if="hasAlert && !isHovered"
                                    class="mx-auto is-size-5 warning--text"
                                    >
                                    {{ alertMessage }}
                                </div>
                            </v-fade-transition>
                        </div>
                        <div v-else>
                            <div class="file-result">
                                <FileLoadState
                                    :title="fileName"
                                    :state="loadState"
                                    :message="error"
                                />
                                <v-icon v-if="$root.appType('bankiros')" color="#95CE5A">mdi-plus-circle</v-icon>
                                <v-icon v-if="$root.appType('cash') || $root.appType('auto') || $root.appType('nordwind')" color="#EB3B66">mdi-plus-circle</v-icon>
                                <v-icon v-if="$root.appType('vtb')" color="#1976D2">mdi-plus-circle</v-icon>
                                <v-icon v-if="$root.appType('travelata')" color="#EC933D">mdi-plus-circle</v-icon>
                            </div>
                        </div>
                    </div>
                </v-fade-transition>
            </div>
        </div>
    </div>
</template>

<script>
import { createPhotoOnBase64 } from "../helpers/Image.js";
import FileLoadState from "@/components/controls/FileLoadState";

export default {
  components: { FileLoadState },
  props: {
    value: Object, // File,
    label: String,
    img: String,
    uploading: Boolean,
    success: Boolean,
    errorMessage: String,
    placeholder: String,
    alertMessage: String,
    rules: Array,
    // docType: Number,
    // connectorMethod: {
    //     type: String,
    //     default: 'savePassportDoc'
    // },
    allowedSize: {
      type: Number,
      default: 12, // в Мб
    },
    allowedExtensions: {
      type: Array,
      default: () => ["jpg", "jpeg", "png", "pdf", "heic"],
    },
  },
  computed: {
    fieldValue() {
      return this.value;
    },
    fileName() {
      return this.value?.name;
    },
    file() {
      return this.$refs.file;
    },
    isUploading() {
      return this.uploading;
    },
    isSuccess() {
      return this.success;
    },
    error() {
      return this.message || this.errorMessage;
    },
    hasAlert() {
      return this.alertMessage;
    },
    containerStates() {
      return {
        "is-success": this.isSuccess || !!this.error,
        "is-dragover": this.isDragOver,
      };
    },
    loadState() {
      return this.isSuccess ? "success" : this.error ? "error" : "waiting";
    },
    accept() {
      return this.allowedExtensions.map((itm) => "." + itm).toString();
    },
  },
  data: () => ({
    // fileName: '',
    isHovered: false,
    isDragOver: false,
    message: "",
    updateField: 0,
  }),
  methods: {
    onDrop(event) {
      // const file = event.dataTransfer.files[0];

      this.isDragOver = false;
      this.upload(event.dataTransfer);
    },
    upload(input) {
      const file = input?.files[0];
      const maxFileSize = this.allowedSize * 1024 * 1024;
      const ext = file.name.split(".").pop();
      const allowedExt = this.allowedExtensions.map((itm) => "(" + itm + ")").join("|") + "$";
      let isAllowedExtension = (ext) => {
        return ext.match(new RegExp(allowedExt, "i"));
      };

      this.message = "";

      if (!isAllowedExtension(ext)) {
        // this.hasError = true;
        this.message = "Формат " + ext + " не поддерживается";
        return;
      }
      if (file.size > maxFileSize) {
        // this.hasError = true;
        this.message = "Размер файла больше " + this.allowedSize + "Мб";
        return;
      }

      createPhotoOnBase64(input)
        .then((b64file) => {
          this.$emit("input", {
            name: file.name,
            data: b64file,
            type: file.type,
          });
        })
        .catch(() => {
          this.message = "Ошибка конвертации";
          // uploadingError();
        })
        .finally(() => this.updateField++);
    },

  },
};
</script>
