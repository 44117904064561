<template>
    <div>
        <div class="modal-screen-desc">
            <p :class="$root.appType('travelata') ? 'travelata_desc-tooltip' : 'desc-tooltip'">
                {{ label }}
            </p>
            <div v-if="anketaName === 'accountNumberBank'" class="helper tooltip-position">
<!--                <span-->
<!--                    v-if="!$root.appType('travelata') && !$root.appType('bankiros') && !$root.appType('southwind')"-->
<!--                    @click="isTooltipFind = !isTooltipFind"-->
<!--                    class="helper-text"-->
<!--                    >Где найти?-->
<!--                </span>-->
                <span
                    :class="addHelperText.class"
                    @click="isTooltipFind = !isTooltipFind"
                    >{{addHelperText.text}}
                </span>
                <Tooltip v-show="isTooltipFind"></Tooltip>
            </div>
        </div>
        <div class="modal-screen-input">
            <v-text-field
                v-mask="mask"
                v-model="fieldValue"
                autocomplete="false"
                :placeholder="placeholder || virtualPlaceholder"
                :type="!isHidden ? type : 'password'"
                solo
                flat
                :outlined="outlined"
                :errorMessages="errorMessages"
                :hide-details="false"
                background-color="white"
                validate-on-blur
                :rules="rules"
                :disabled="isDisabled"
                @blur="onBlur"
                @focus="onFocus"
                :key="updateKey"
                :autofocus="autofocus"
                :class="!$root.appType('cash') && !$root.appType('auto') ? `${$root.state.type()}_input` : ''"
                >
                <template #append>
                    <v-icon
                        v-if="hideValue !== undefined && ($root.appType('cash') || $root.appType('auto'))"
                        @click="isHidden = !isHidden"
                        class="hide-icon"
                        >{{ isHidden ? "mdi-eye-off" : "mdi-eye" }}
                    </v-icon>

                    <span class="textSecondary--text">{{ appendText }}</span>
                </template>
            </v-text-field>
        </div>

        <label class="is-size-5">{{ comment }}</label>
        <slot name="inputAdditionalInfo"></slot>
    </div>
</template>

<script>
import Tooltip from "@/components/controls/Tooltip";
import { replaceToCyrilic } from "../helpers/ReplaceToCyrylic";

export default {
    props: {
        value: [Number, String, Boolean],
        label: String,
        comment: String,
        append: String,
        mask: String,
        maskValue: String,
        type: String,
        rules: Array,
        placeholder: String,
        errorMessages: String,
        outlined: Boolean,
        hideValue: {
            type: Boolean,
            default: () => undefined,
        },
        disabled: Boolean,
        replaceEngToRu: {
            type: Boolean,
            default: true,
        },
        // Ограничения ввода:
        isBank: Boolean,
        isPhone: Boolean,
        isMoney: Boolean,
        localeString: Boolean,
        isCyrilic: Boolean,
        isCyrilicIssued: Boolean,
        isCyrilicPlace: Boolean,
        isEngEmail: Boolean,
        anketaName: String
    },

    components: {
        Tooltip,
    },

    mounted() {
        if (this.label === "Номер счета") {
            document.addEventListener("click", (e) => {
                if (
                    !e.target.closest(".tooltip") &&
                    !e.target.closest(".tooltip-position")
                ) {
                    this.isTooltipFind = false;
                }
            });
        }
    },

    computed: {
        anketa(){
          return this.$root.anketa
        },
        fieldValue: {
            get() {
                if (this.isMoney && this.value) {
                    return this.value
                        .toString()
                        .replace(/(\d)(?=(\d{3})+([^\d]|$))/g, "$1 ");
                }
                else {
                    return this.value;
                }
            },
            set(val) {
                let oldval = val;

                if (val != this.value) {

                    val = this.applyInputRestrictions(val);

                    if(this.anketaName === 'incomeMain' || this.anketaName === 'income' || this.anketaName === 'monthlyCreditPayment'){
                        val = val.replace(/\D/g, '')
                        val = val.split('').join('').replace(/\B(?=(\d{3})+(?!\d))/g, " ").split('').join('').trim();
                        this.$emit("input", val);
                        this.updateKey++;
                        this.autofocus = true;
                    }
                    // else if(this.anketaName === 'phone' && this.anketa.phone.clientError){
                    //
                    //     this.$emit("input", val);
                    //     console.log('text val', val)
                    //     console.log('text oldval', oldval)
                    //     console.log('text this.value', this.value)
                    //     // console.log('text field phone')
                    //     // this.anketa.phone.disabled = true
                    //     this.$root.state.buttonsState.registration
                    //     // if (val != oldval) {
                    //     //     this.anketa.phone.disabled = false
                    //     // }
                    //     // this.anketa.phone.clientError = false
                    //     // this.updateKey++;
                    //     // this.autofocus = true;
                    // }
                    // else if(this.anketaName === 'passport' && this.anketa.phone.clientError){
                    //
                    //     this.$emit("input", val);
                    //     console.log('text val', val)
                    //     console.log('text oldval', oldval)
                    //     console.log('text this.value', this.value)
                    //     // console.log('text field phone')
                    //     // this.anketa.phone.disabled = true
                    //     this.$root.state.buttonsState.registration
                    //     // if (val != oldval) {
                    //     //     this.anketa.phone.disabled = false
                    //     // }
                    //     // this.anketa.phone.clientError = false
                    //     // this.updateKey++;
                    //     // this.autofocus = true;
                    // }
                    else {
                        this.$emit("input", val, this.value);
                        if (val != oldval) {
                            // перерисовка позволяет видеть в поле ввод с ограничениями
                            this.updateKey++;
                            // autofocus позволяет мобильной клавиатуре оставаться на месте
                            // при перерисовке поля по updateKey
                            this.autofocus = true;
                        }

                        else {
                            this.autofocus = false;
                        }

                    }


                }
            },

            // set(val) {
            //     let oldval = val;

            //     if (val != this.value) {
            //         val = this.applyInputRestrictions(val);

            //         this.$emit("input", val);

            //         if (val != oldval) {
            //             // перерисовка позволяет видеть в поле ввод с ограничениями
            //             this.updateKey++;
            //             // autofocus позволяет мобильной клавиатуре оставаться на месте
            //             // при перерисовке поля по updateKey
            //             this.autofocus = true;
            //         }

            //         else {
            //             this.autofocus = false;
            //         }
            //     }
            // },

        },
        appendText() {
            return this.append;
        },
        virtualPlaceholder() {
            if (this.mask) {
                return this.mask.replace(/#/g, "0");
            } else {
                return null;
            }
        },

        // isHidden() {
        //   return this.hideValue;
        // },

        isDisabled() {
            return this.disabled;
        },

        // hiddingText(){
        //     // !isHidden ? type : 'password'
        //
        // }

        addHelperText(){
            const oldTypes = ['cash', 'auto'];
            const type = this.$root.state.type();
            const oldText = 'Где найти?';
            const newText = 'Где его найти?';
            let textAndStyles = {
                text: null,
                class: `${type}_helper-text`
            }
            if(oldTypes.includes(type)) {
                textAndStyles.text = oldText;
                return textAndStyles;
            }else {
                textAndStyles.text = newText;
                return textAndStyles;
            }
        }
    },
    data() {
        return {
            updateKey: 0,
            autofocus: false,
            isTooltipFind: false,
            prevValue: "",
            isHidden: this.hideValue,
        };
    },

    methods: {
        applyInputRestrictions(val) {
            let oldval = val;
            let key;

            if (this.isMoney) {
                val = val.replace(/\D/g, "");
            }
            if (this.isEngEmail) {
                val = val.replace(/[^a-zA-Z\-0-9.@]/g, "");
            }
            if (this.isCyrilic) {
                val = val.replace(/[^а-яА-ЯёЁ -]/g, "");
            }
            if (this.isCyrilicIssued) {
                val = val.replace(/[^0-9А-яЁё/\\(№\-.,")\s]/g, "");
            }
            if (this.isCyrilicPlace) {
                val = val.replace(/[^0-9А-яЁё("\-.)\s]/g, "");
            }
            if (this.localeString) {
                // val = Number(val.replace(/\s+/g, "")).toLocaleString();
                // val = val.replace(/\D/g, "");
                val = val.replace(/\D/g, "");
            }
            if (this.isBank) {
                if (val.length === 1 && this.value === "") {
                    val = val.replace(/^(.{0,4})/, "4081" + val);
                }
            }
            if (this.isPhone) {
                if (val.length === 1 && this.value === "") {
                    val = val.replace(/^(.{0,2})/, "+7" + val);
                }
            }
            if (this.replaceEngToRu) {
                key = oldval.replace(val, "");
                if (key.length == 1) {
                    val += replaceToCyrilic(key);
                }
            }

            return val;
        },
        onBlur(e) {
            this.$emit("blur", e);
        },
        onFocus() {
            if (this.value === "" && (this.maskValue ?? this.mask)) {
                const inputValue = this.maskValue || this.mask;
                const focusValue = inputValue.replace(/[\s- #]/g, "");
                console.log('focusValue', focusValue)
                if (focusValue !== "") {
                    this.$emit("input", focusValue);
                    this.updateKey++;
                    this.autofocus = true;
                }
            }
        },
        // addHelperText(){
        //     const oldTypes = ['nordwind', 'cash', 'vtb'];
        //     const type = this.$root.state.type();
        //     const oldText = 'Где найти?';
        //     const newText = 'Где его найти?';
        //     let textAndStyles = {
        //         text: null,
        //         class: `${type}_helper-text`
        //     }
        //     if(oldTypes.includes(type)) {
        //         textAndStyles.text = oldText;
        //         return textAndStyles;
        //     }else {
        //         textAndStyles.text = newText;
        //         return textAndStyles;
        //     }
        // }
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_travelata-variables.scss';

.travelata {
    &_helper-text {
        @include tr-text(12px, 400 ,16px);
        font-family: 'OpenSansR';
        color: #059BCE;
        cursor: pointer;
    }
}
.bankiros {
    &_helper-text {
        font-family: Lato;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
        letter-spacing: -0.4px;
        color: #388EC3;
        cursor: pointer;
    }
}
.southwind {
    &_helper-text {
        @include tr-text(12px, 400 ,16px);
        font-family: 'OpenSansR';
        color: #D6181E;
        cursor: pointer;
    }
}
.nordwind {
    &_helper-text {
        @include tr-text(12px, 400 ,16px);
        font-family: 'OpenSansR';
        color: #D6181E;
        cursor: pointer;
    }
}

.cash, .auto {
    &_helper-text {
        @include tr-text(12px, 400 ,16px);
        font-family: 'OpenSansR';
        color: #eb3b66;
        cursor: pointer;
    }
}

.vtb {
    &_helper-text {
        @include tr-text(12px, 400 ,16px);
        font-family: 'OpenSansR';
        color: #1976D2;
        cursor: pointer;
    }
}
</style>
