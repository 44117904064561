<template>
    <div class="pa-0 ma-0 conditions">
        <div
            v-if="$root.appType('cash') || $root.appType('auto') ||  $root.appType('nordwind') || $root.appType('southwind')"
            class="container-info-limit_initpayment"
            >
            <p
                :class="{'text--pink': $root.appType('auto')}"
                class="container_info_limit container-info-limit_description"
                >
                {{windowWidth > 420 ? 'Ежемесячный платеж' : 'Ежемес. платеж'}}
            </p>
            <div
                class="container-info-limit_price"
                >
                <p class="text" id="skip-value-payment">{{ conditionValue }}</p>
                <span class="ruble-icon">&#8381;</span>
            </div>
        </div>
        <div
            v-if="$root.appType('vtb')"
            class="vtb-info-limit  vtb-info-limit_initpayment"
            >
            <p
                class="container_info_limit vtb-info-limit_description"
                >
                {{windowWidth > 420 ? 'Ежемесячный платеж' : 'Ежемес. платеж'}}
            </p>
            <div
                class="vtb-info-limit_price text-no-wrap"
                >
                <p class="text" id="skip-value-payment text-no-wrap">{{ conditionValue }}</p>
                <span class="ruble-icon text-no-wrap">&#8381;</span>
            </div>
        </div>
        <div
            v-if="$root.appType('travelata')"
            class="travelata-info-limit  travelata-info-limit_initpayment"
            >
            <p
                class="container_info_limit travelata-info-limit_description"
                >
                {{windowWidth > 420 ? 'Ежемесячный платеж' : 'Ежемес. платеж'}}
            </p>
            <div
                class="travelata-info-limit_price text-no-wrap"
                >
                <p class="text" id="skip-value-payment text-no-wrap">{{ conditionValue }}</p>
                <span class="travelata-ruble-icon text-no-wrap">&#8381;</span>
            </div>
        </div>
    </div>
</template>

<script>
import { moneyFormatWithSpace } from "@/components/helpers/MoneyFormat.js";
export default {
  props: {
    value: [String, Number],
    valueClass: String,
    append: {
      type: String,
      default: "₽"
    },
    loading: Boolean

  },
  computed: {
    conditionValue() {
      return moneyFormatWithSpace(this.value);
    },
    windowWidth() {
      return this.$root.anketa.windowWidth;
    },
    isLoading() {
      return this.loading;
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vtb-variables.scss";
@import "@/assets/scss/_travelata-variables.scss";

.text--pink {
    color: #EB3B66 !important;
}

.vtb {
    &-info-limit_initpayment {
        margin-top: 32px;
    }

    &-info-limit {
        display: flex;
        flex-direction: row;
        justify-content: end;
        align-items: baseline;
        // width: 100%;
        position: relative;
    }
    &-info-limit_description {
        @include medium-text-thin;
        color: #2F3441;

        @media (max-width: 430px) {
            margin-right: 20px;
            text-align: left;

        }
        @media (max-width: 362px) {

            position: relative;
            top: -12px;
        }

        @media (max-width: 330px) {
            margin-right: 10px;
        }
    }

    &-info-limit_price {
        @include bold-text;
        color: #2F3441;
        display: flex;
        justify-content: space-between;
        gap: 10px;

        @media (max-width: 400px) {
            font-size: 30px;
        }
    }
}

.container-info-limit_price {
    height: 40px;
    font-family: RobotoBold;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 50%;
    margin: 0;
    white-space: nowrap;

    & > .text {
        font-style: normal;
        font-weight: 900;
        font-size: 32px;
        line-height: 48px;

        display: flex;
        align-items: center;
        font-family: NerisBlack;
        text-align: right;
        color: #0a0a0d;
        margin: 0;
    }

    & > span {
        font-size: 32px;
        line-height: 1.3em;
        display: flex;
        align-items: center;
        text-align: right;
        color: #0a0a0d;
        padding: 0 0 0 5px;
        font-weight: 900;
        transform: translateY(6.6%);
        font-family: SFProBold !important;
    }
}

.travelata {
    &-info-limit_initpayment {
        margin-top: 32px;
    }

    &-info-limit {
        display: flex;
        flex-direction: row;
        justify-content: end;
        align-items: baseline;
        // width: 100%;
        position: relative;
    }
    &-info-limit_description {
        @include tr-text(18px);
        color: #333;
        font-family: 'OpenSansR';

        @media (max-width: 430px) {
            margin-right: 20px;
            text-align: left;

        }
        @media (max-width: 362px) {

            position: relative;
            top: -12px;
        }

        @media (max-width: 330px) {
            margin-right: 10px;
        }
    }

    &-info-limit_price {
        @include tr-text(32px, 800, 38px);
        color: #333;
        font-family: 'OpenSansEB';
        display: flex;
        justify-content: space-between;
        gap: 10px;

        @media (max-width: 400px) {
            font-size: 30px;
        }
    }

    &-ruble-icon {
        @include tr-text(28px, 800, 38px);
        color: #333;
        font-family: "Roboto";
    }

}
</style>
