<template>
    <div id="checkApplication">
        <h1
            :class="addClass('forms_title')"
            class="mb-5"
            >Заявка ранее уже была отправлена</h1>
        <p class="mb-5">После отправки новой заявки текущая будет аннулирована, вернуться к ее оформлению?</p>
        <div
            class="account-info-well"
        >
            <table class="table-well">
                <tr v-if="anketa.creditInformation.requestedSumm">
                    <td>Сумма заявки</td>
                    <td>{{ anketa.creditInformation.requestedSumm }} &#8381;</td>
                </tr>
                <tr v-if="anketa.creditInformation.months">
                    <td>Количество месяцев</td>
                    <td>{{ anketa.creditInformation.months }}</td>
                </tr>
                <tr v-if="anketa.creditInformation.monthlyPayment">
                    <td>Расчетный ежемесячный платеж</td>
                    <td>{{ anketa.creditInformation.monthlyPayment }} &#8381;</td>
                </tr>
            </table>
        </div>

        <div class="buttons">
            <v-btn
                :loading="loading"
                :disabled="loading"
                @click="choise(false)"
                :class="!$root.appType('vtb') ? 'rectangle-button' : 'vtb-button'"

            >Да
            </v-btn>
            <v-btn
                :loading="loading"
                :disabled="loading"
                @click="choise(true)"
                :class="!$root.appType('vtb') ? 'rectangle-button' : 'vtb-button'"

            >Нет
            </v-btn>
        </div>

        <p
            class="error-text"
            v-show="errorAuthTextApi.status"
        >Ошибка авторизации {{ errorAuthTextApi.text }}</p>
    </div>
</template>

<script>
import moment from "moment";
export default {
    computed: {
        anketa() {
            return this.$root.anketa;
        },
        connector() {
            return this.$root.connector;
        },
        isNordwind(){
           return this.$root.state.isNordwind()
        }
    },

    data: () => ({
        errorAuthTextApi: {
            status: false,
            text: ''
        },
        loading: false,
        exceptionsTypes: ['cash', 'auto','nordwind']
    }),
    mounted() {
        // if(this.isNordwind){
        //     this.$root.metrika96799237.reachGoal("expectation");
        // }
        // else if(this.$root.appType('cash')) {
        //     this.$root.metrika88149184.reachGoal("expectation");
        // }
        // else if(this.$root.appType('vtb')) {
        //     this.$root.metrika97329470.reachGoal("expectation");
        // }
        // else if(this.$root.appType('travelata')) {
        //     this.$root.metrika97329470.reachGoal("expectation");
        // }
        this.$root[`metrika${this.$root.addYandexMetricGoals()}`].reachGoal("expectation");

    },
    methods: {
        async choise(merge) {
            if (merge) {
                this.$root.mindbox("Обнаружена старая заявка - переход к одобрению Кэш");
                // await this.connector.getTokenAccessAndRefreshWithBirthday(
                //     this.anketa.phone.valueFirstDigitIs9(), this.anketa.birthdate.value
                await this.connector.isStatusApplication()
                    .then(response => {
                    this.loading = true;
                    this.errorAuthTextApi.status = false;
                    if (response.success) {
                        // localStorage.setItem("tokenAccess", response.access);
                        // localStorage.setItem("tokenRefresh", response.refresh);
                        let customer_confirmation_details = {
                            transfer_to_third_parties: this.anketa.transferToThirdParties.value,
                            advertising: this.anketa.toggleAdvertising.value,
                            has_no_overdue_debts: this.anketa.hasNoOverdueDebts.value,
                            ip: this.anketa?.juicyData?.IPAddress,
                            phone_number: this.anketa.phone.cleanValue(),
                            device_fingerprint: navigator.userAgent,
                            timestamp: localStorage.getItem('token_dt')
                                ? moment(localStorage.getItem('token_dt'), "YYYY-MM-DD HH:mm:ss").add(3, "hours").format("YYYY-MM-DD HH:mm:ss")
                                : null,
                        }

                        const requiredCustomerFields = [
                            "transfer_to_third_parties",
                            "advertising",
                            "has_no_overdue_debts",
                            "ip",
                            "phone_number",
                            "device_fingerprint",
                            "timestamp"
                        ];

                        const isValid = requiredCustomerFields.every(
                            key => key in customer_confirmation_details &&
                                customer_confirmation_details[key] !== null &&
                                customer_confirmation_details[key] !== undefined &&
                                customer_confirmation_details[key] !== ''
                        );

                        let createData = {
                            rate_id: this.anketa.conditions.rateId,
                            period: this.anketa.term.valueTerm,
                            percent: this.anketa.term.percent,
                            // account: this.anketa.accountNumberBank.cleanValue(),
                            // bik: this.anketa.accountBank.bik,
                            // card_number: this.anketa.accountCard.cleanValue(),
                            // bank_name: this.anketa.accountBank.valueAdditionalInfoBankName,
                            // correspondent_account: this.anketa.accountBank.valueAdditionalInfoCorrespondentAccount,

                            amount: this.anketa.initialPayment.value,
                            monthly_credit_payment: this.anketa.monthlyCreditPayment.value ? this.anketa.monthlyCreditPayment.value.split(" ").join("") : null,
                            //account: this.anketa.receiveOnCard.value && this.anketa.conditions.initialPayment <= 40000 ? "" : this.anketa.accountNumberBank.cleanValue(),
                            account: "40811111111111111111",
                            // bik: this.anketa.receiveOnCard.value && this.anketa.conditions.initialPayment <= 40000 ? "" : this.anketa.accountBank.bik,
                            bik: "111111111",
                            // card_number: this.anketa.receiveOnCard.value && this.anketa.conditions.initialPayment <= 40000 ? this.anketa.accountCard.cleanValue() : "",
                            card_number: "1111111111111111",
                            // bank_name: this.anketa.accountBank.valueAdditionalInfoBankName,
                            bank_name: "Банк не найден. Проверьте правильность БИК.",
                            // correspondent_account: this.anketa.accountBank.valueAdditionalInfoCorrespondentAccount,
                            correspondent_account: "1111111111111111",

                            income_amount: this.anketa.incomeMain.cleanValue(),
                            token: localStorage.getItem('token'),
                            token_dt: localStorage.getItem('token_dt'),
                            phone: this.anketa.phone.valueFirstDigitIs9(),
                            merge,
                        };

                        if (isValid) {
                            createData = {
                                ...createData,
                                customer_confirmation_details,
                            }
                        } else {
                            console.warn("customer_confirmation_details не полный")
                        }

                        this.connector.isAuthAndCreateApplication(createData)
                            .then(response => {
                                if(response.success) {
                                    if(response.application_id) {
                                        console.log('applicationId', response.application_id)
                                        this.integrationApiCash(response.application_id)
                                        let esiaData = {
                                            step: 4,
                                            applicationId: Number(response.application_id),
                                            sessionId: localStorage.getItem('sessionId'),
                                            token: localStorage.getItem('comundaToken'),
                                            tokenDt: localStorage.getItem('comundaTokenDt'),
                                        }
                                        this.connector.startProccess('esia1', esiaData)
                                    }
                                }
                                this.connector.isStatusApplication()
                                    .then((response) => {

                                        console.log('response check application', response)
                                        this.loading = false;
                                        let stepData = {
                                            success: true,
                                            prev: 'checkApplication',
                                            next: 'statusWait',
                                        }

                                    this.$emit('changeTemplate', stepData, false);
                                })

                            .catch(error => {
                                this.loading = false;
                                this.errorAuthTextApi.status = true;
                                this.errorAuthTextApi.text = error;
                            });

                        this.loading = false;
                            let stepData = {
                                success: true,
                                prev: 'checkApplication',
                                next: 'statusWait',
                            }
                            this.$emit('changeTemplate', stepData, false);
                        });
                    } else {
                        this.errorAuthTextApi.status = true;
                        this.errorAuthTextApi.text = '';
                    }
                }).catch(error => {
                    this.loading = false;
                    this.errorAuthTextApi.status = true;
                    this.errorAuthTextApi.text = error;
                });

            } else {
                this.$root.mindbox("Обнаружена старая заявка - переход к оформлению Кэш");
                let stepData = {
                    success: true,
                    prev: 'checkApplication',
                    next: 'accessApplication',
                }

                switch (this.anketa.status) {
                    case 'needMoreData':
                        stepData.next = 'needMoreData'
                        break;

                    case 'processing':
                        this.$root.mindbox("Проверка заявки Кэш");
                        stepData.next = 'statusWait'
                        break;
                }

                this.$emit('changeTemplate', stepData, false);
            }
        },

        addClass(cssClass){
            let searchingClass = null
            // console.log('addClass', this.exceptionsTypes.find(this.$root.state.type()))
            if(this.exceptionsTypes.find(el => el === this.$root.state.type())) {
                searchingClass = `${cssClass}`
            }else {
                searchingClass = `${this.$root.state.type()}_${cssClass}`
            }
            return searchingClass
        },


        integrationApiCash(appId){
            this.connector.integrationApiCash(appId)
        },
    },
}
</script>
