<template>
    <div :key='componentKey' class="travelata_template_slider">
        <div class='travelata_template_slider_inner' style="background: none;">
            <div class='container-width '>
                <div class='travelata_template_slider_body'>
                    <div class='block-2-announce'>
                        <div data-popmechanic-embed="72227"></div>
                        <div class='container-info sliders sliders-position' id='widget' >
                            <Slider
                                v-model='anketa.initialPayment.value'
                                v-bind='anketa.initialPayment'
                                :nameSlider='sliderinitialPayment'
                                :min='MinSum'
                                :max='MaxSum'
                                :step='1000'
                                :sliderColors="sliderColors"
                                @input='anketa.initialPayment.input'
                                @slider-mouseup="anketa.initialPayment.onMousedown"
                                >
                                <div class='container_info_limit slider-summ' slot='labelSliderSumm'>
                                    <p class='travelata-info-limit_description travelata-info-limit_description__ticks'
                                    >{{ minSumForDown }} &#8381;
                                    </p>
                                    <p class='travelata-info-limit_description travelata-info-limit_description__ticks'>
                                        {{ maxSumForDown }}
                                        <span>&#8381;</span>
                                    </p>
                                </div>
                            </Slider>
                            <Slider
                                v-model='anketa.term.value'
                                v-bind='anketa.term'
                                :tick-size='sliderPeriodSTR.length'
                                :slider-array='sliderPeriod'
                                :tick-labels='sliderPeriodSTR'
                                :nameSlider='sliderTerm'
                                :min='1'
                                :max='sliderPeriod.length'
                                :step='1'
                                :sliderColors="sliderColors"
                                @input='anketa.term.input'
                                :metricGoalName="'slider_term'"
                                @slider-mouseup="anketa.term.onMousedown"
                            />
                            <Condition v-model='anketa.conditions.monthlyPayment'>Ежемесячный платеж</Condition>
                            <p></p>
                            <div
                                v-if='anketa.actionInfo && params === "ac"'
                                class='travelata_cashback_container'>
                                <div class='travelata_cashback_title'>
                                    <h1 class="text-left">
                                        Ваш кешбэк: {{ anketa.term.valueTerm === 12 ? cashback : "0" }}
                                        <b class='travelata-ruble-icon'>&#8381;</b>
                                    </h1>
                                </div>
                                <p
                                    class="travelata_cashback_text">
                                    {{anketa.term.valueTerm === 12 ? "": "Чтобы получить кешбэк, выберите срок от 3х месяцев"}}
                                </p>
                                <p class="travelata_cashback_details" @click="$vuetify.goTo('#cashback', 500)">
                                    Подробнее
                                </p>
                            </div>
                            <div
                                v-if='anketa.actionInfo && params === "acnew"'
                                class='travelata_cashback_container'>
                                <div class='travelata_cashback_title'>
                                    <h1>
                                        Ваш кешбэк: {{ anketa.term.valueTerm === 1 ? "0" : cashback }}
                                        <b class='travelata-ruble-icon'>&#8381;</b>
                                    </h1>
                                </div>
                                <p class="travelata_cashback_text">
                                    {{anketa.term.valueTerm === 1 ? "Чтобы получить кешбэк, выберите срок от 3х месяцев": ""}}
                                </p>

                                <p class="travelata_cashback_details" @click="$vuetify.goTo('#cashback', 500)">
                                    Подробнее
                                </p>
                            </div>

<!--                            <v-btn-->
<!--                                class='travelata-button travelata-button-prime modal-open'-->
<!--                                data-modal='form-modal'-->
<!--                                id='get-money'-->
<!--                                @click='changeStatusModal()'-->
<!--                                >Получить-->
<!--                                <span-->
<!--                                    class="travelata-button_ammount"-->
<!--                                    id='button-value-amount'>-->
<!--                                    {{ anketa.conditions.initialPayment.toLocaleString().replace(/,/, " ") }}-->
<!--                                    <b class='travelata-ruble-icon'>&#8381;</b>-->
<!--                                </span>-->
<!--                            </v-btn>-->
                            <MainButton
                                @click="changeStatusModal()"
                                :radius="4"
                                data-modal="form-modal"
                                id="get-money"
                                >Получить
                                <span
                                    class="travelata-button_ammount"
                                    id='button-value-amount'>
                                    {{ anketa.conditions.initialPayment.toLocaleString().replace(/,/, " ") }}
                                    <b class='travelata-ruble-icon_white'>&#8381;</b>
                                </span>
                            </MainButton>
                        </div>
                        <div
                            v-show="!showSliderComplited.status && !showSliderComplited.error.status"
                            class='preloader'
                            >
                            <img class src="@/assets/img/travelata/travelata-preloader.gif"/>
                        </div>
                        <div
                            v-show='showSliderComplited.error.status'
                            class="error-text"
                            >
                            <p>{{ showSliderComplited.error.text }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- </div> -->
        </div>
        <!-- </div> -->
    </div>
</template>

<script>
import Slider from "@/components/Slider";
import Condition from "@/components/Condition.vue";
import {moneyFormatWithSpace} from "@/components/helpers/MoneyFormat.js";
import MainButton from "@/components/global/MainButton";
// Добавить при добавлении промокода
// import FieldsCard from "@/components/FieldsCard";

export default {
    name: "TravelataTemplateSlider",
    components: {
        Slider,
        Condition,
        MainButton
        // Добавить при добавлении промокода
        // FieldsCard

    },
    props: {
        countPeriod: Number,
        reRenderComponent: Boolean,
    },
    data: () => ({
        componentKey: 0, //для принудительного повторного рендинга компонента
        showSliderComplited: {
            status: false,
            error: {
                status: false,
                text: "Системная ошибка",
            },
        },
        MinSum: 3000,
        MaxSum: 60000,
        sliderPeriod: [1],
        sliderPeriodSTR: ["1"],
        sliderinitialPayment: {
            name: "Сумма",
            unit: "&#8381;",
            useModel: true,
        },
        sliderTerm: {
            name: "Срок",
            unit: "мес.",
            useModel: false,
        },
        params: '',
        textComands: {
            info: 'Уточните у сотрудника в отделении',
            error: 'Неверный код, обратитесь к сотруднику в отделение',
            ok: 'Код успешно применён'
        },
        textToShow: {
            text: 'Уточните у сотрудника в отделении',
            class: 'travelata_promocode_info'
        },
        promocodeIsUsed: false

    }),
    watch: {
        reRenderComponent: function () {
            this.forceRerender();
        },
    },
    methods: {
        changeStatusModal() {
            this.$root.metrika98070141.reachGoal("get_money");
            // this.$root.mindbox("Выбрал сумму на калькуляторе");
            this.$root.mindbox("Экран авторизации Кэш");
            this.$root.anketa.isOpenModal = true;

        },
        forceRerender() {
            this.componentKey += 1;
        },
        limitSumDefinitions(urlParams) {
            if(urlParams.get("e")) this.$root.mindbox("Посетил страницу. Лимит 15k");
            if(urlParams.get("w")) this.$root.mindbox("Посетил страницу. Лимит 30k");
            if(urlParams.get("o")) this.$root.mindbox("Посетил страницу. Лимит 50k");
            if(urlParams.get("h")) this.$root.mindbox("Посетил страницу. Лимит 80k");
            if(urlParams.get("t")) this.$root.mindbox("Посетил страницу. Лимит 100k");
            if(urlParams.get("m")) this.$root.mindbox("Посетил страницу. Лимит 150k");
            if(urlParams.get("r")) this.$root.mindbox("Посетил страницу. Лимит 100k. NearPrime");
            if(urlParams.get("y")) this.$root.mindbox("Посетил страницу. Лимит 500k. NearPrime");
            if(urlParams.get("nr")) this.$root.mindbox("Посетил страницу. Лимит 20к. Нерезидент");
        },
        getFirstDate(urlParams) {
            this.connector
                .isMainInformationCalc(urlParams.get("s"))
                .then((response) => {
                    const lengthOfSliderParams = Object.keys(response.slider_params).length;
                    console.log('lengthOfSliderParams', lengthOfSliderParams)
                    if(lengthOfSliderParams > 0) {
                        if (response.success && response.slider_params.maxSum !== 0) {
                            this.showSliderComplited.status = true;
                            this.anketa.firstDateSlider = this.getObjSort(response.slider_params.listStocks);

                            if (urlParams.get("ac") !== null) {
                                this.anketa.actionInfo = true;
                                this.params = 'ac'
                            }

                            else if (urlParams.get("acnew") !== null) {
                                this.anketa.actionInfo = true
                                this.params = 'acnew'
                            }
                            for (let k in this.anketa.firstDateSlider) {
                                this.anketa.firstDateSlider[k].periodStr = String(this.anketa.firstDateSlider[k].period);
                            }
                            this.sliderPeriod = this.getsliderPeriod(this.anketa.firstDateSlider);

                            this.sliderPeriodSTR = this.getsliderPeriodSTR(this.anketa.firstDateSlider);
                            this.MaxSum = response.slider_params.maxSum;
                            this.MinSum = response.slider_params.minSum;

                            if (urlParams.get("sum")) {
                                this.anketa.initialPayment.value = +urlParams.get("sum") * 1000;
                            } else {
                                this.anketa.initialPayment.value = response.slider_params.maxSum;
                            }

                            if(urlParams.get("term") && !isNaN(urlParams.get("sum")) && !isNaN(urlParams.get("term")) && urlParams.get("User_ID")) {
                                const allTerms = this.anketa.firstDateSlider.map(obj => obj.period)
                                const termValue = urlParams.get("term");

                                if(allTerms.includes(parseInt(termValue))){
                                    const getIndexOfTerm = this.anketa.firstDateSlider.findIndex(item => item.period === parseInt(termValue));
                                    this.anketa.term.value = parseInt(getIndexOfTerm) + 1;
                                    this.anketa.isOpenModal = true;
                                }else {
                                    const getIndexOfTerm = this.anketa.firstDateSlider.findIndex(item => item.period === parseInt(response.slider_params.defaultPeriod));
                                    this.anketa.term.value = parseInt(getIndexOfTerm) + 1;
                                }
                            }else {
                                for (let i in this.sliderPeriod) {
                                    console.log(this.sliderPeriod[i], i);
                                    if (this.sliderPeriod[i] == response.slider_params.defaultPeriod) {
                                        this.anketa.term.value = parseInt(i) + 1;
                                    }
                                }
                            }

                            // for (let i in this.sliderPeriod) {
                            //     if (this.sliderPeriod[i] == response.slider_params.defaultPeriod) {
                            //         this.anketa.term.value = parseInt(i) + 1;
                            //     }
                            // }
                            this.anketa.conditions.calculate(this.anketa.initialPayment.value, this.anketa.term.value);
                            return this.anketa.firstDateSlider;
                        } else {
                            this.anketa.sliderParams = false;
                        }
                    }
                    else {
                        this.anketa.showCalculatorErrors.hasError = true;
                        this.anketa.showCalculatorErrors.textError = "Оформление временно недоступно";
                        this.anketa.showCalculatorErrors.reason = "пустой slider_params";
                    }
                })
                .catch((error) => {
                    this.anketa.showCalculatorErrors.hasError = true;
                    this.anketa.showCalculatorErrors.textError = "Что-то пошло не так... Попробуйте обновить страницу.";
                    this.anketa.showCalculatorErrors.reason = "ошибка ответа get_params";
                    this.showSliderComplited = {
                        status: false,
                        error: {
                            status: true,
                            text: error,
                        },
                    };
                });
        },
        getObjSort(obj) {
            return obj.sort((a, b) => (a.period > b.period ? 1 : -1));
        },
        getsliderPeriod(obj) {
            let array = [];
            for (let k in obj) {
                array.push(obj[k].period);
            }
            return array;
        },
        getsliderPeriodSTR(obj) {
            let array = [];
            for (let k in obj) {
                array.push(obj[k].periodStr);
            }
            return array;
        },

        // travelataPromocodeInfo(){
        //
        //     if(this.anketa.travelataPromocode.value === '') {
        //         this.textToShow.text = this.textComands.error
        //         this.textToShow.class = 'travelata_promocode_info-error'
        //         this.promocodeIsUsed = false
        //     }
        //     else if(this.anketa.travelataPromocode.value.length !== 6) {
        //         this.textToShow.text = this.textComands.error
        //         this.textToShow.class = 'travelata_promocode_info-error'
        //         this.promocodeIsUsed = false
        //     }
        //     else if(this.anketa.travelataPromocode.value.length === 6) {
        //         this.textToShow.text = this.textComands.ok
        //         this.textToShow.class = 'travelata_promocode_info'
        //         this.promocodeIsUsed = true
        //
        //     }
        // },
        countingTerm(term, textToshow){
            return this.anketa.term.termValue === term ? textToshow : '';
        }

    },

    computed: {
        anketa() {
            return this.$root.anketa;
        },
        connector() {
            return this.$root.connector;
        },
        cashback: {
            get() {
                return moneyFormatWithSpace((this.anketa.initialPayment.value / 100) * this.anketa.term.percent * (this.anketa.term.valueTerm - 1));
            },
        },

        minSumForDown() {
            return moneyFormatWithSpace(this.MinSum);
        },

        maxSumForDown() {
            return moneyFormatWithSpace(this.MaxSum);
        },

        minTerm: {
            get() {
                return Math.min.apply(null, this.sliderPeriod);
            },
        },
        maxTerm: {
            get() {
                return Math.max.apply(null, this.sliderPeriod);
            },
        },
        templateText: {
            set() {
                return this.anketa.conditions.initialPayment > 40000 ? false : true;
            },
            get() {
                return this.anketa.conditions.initialPayment > 40000 ? false : true;
            },
        },

        getUrlParams() {
            let urlParams = new URLSearchParams(window.location.search);
            return urlParams.has('s');
        },

        sliderColors(){
            // let colors = this.$root.$vuetify.theme.defaults.light
            return {
                bg: '#059BCE',
                track: '#c8c8c8'
            }
        },

    },
    created() {
        let urlParams = new URLSearchParams(window.location.search);
        this.anketa.mindboxId = urlParams.get("c");
        this.getFirstDate(urlParams);
        this.limitSumDefinitions(urlParams);

    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_travelata-variables.scss";

.container-info {
    width: 100%;
}

.travelata {
    &_template_slider {
        max-width: 500px;
        width: 100%;
        &_body {
            background-color: #fff;
            padding: 25px;
            border: 1px solid #D4D7DF;
            border-radius: 8px;

            //@media (max-width: 370px) {
            //    margin-bottom: 40px;
            //}
        }
    }

    &_input {
        border: 2px var(--v-primary-base) solid;
        border-radius: 8px;

    }

    &-button {
        &_ammount {
            margin-left: 10px;
        }
    }

    &-ruble-icon {
        @include tr-text(20px, 500);
        font-family: 'Roboto';
        color: #333;

        &_white {
            @include tr-text(20px, 500);
            font-family: 'Roboto';
            color: #fff;
        }
    }

    &_cashback {
        &_container {
            background-color: #F9F9F9 !important;
            // margin-top: 30px;
            border-radius: 4px;
            padding: 24px;
            display: flex;
            gap: 10px;
            align-items: flex-start;
            margin-bottom: 30px;
            //height: 76px;
            flex-direction: column;

        }

        &_title {
            & > h1 {
                @include tr-text(20px, 600);
                font-family: 'OpenSansSB';
                color: #333;

                @media (max-width: 450px) {
                    font-size: 16px !important;
                }

            }
        }

        &_text {
            @include tr-text();
            font-family: 'OpenSansR';
            color: #EC933D;
            margin: 0;

            @media (max-width: 370px) {
                font-size: 14px !important;
            }
        }

        &_details {
            padding: 2px 16px;
            border-radius: 32px;
            @include tr-text(14px, 700);
            font-family: 'OpenSansB';
            color: #059BCE;
            padding: 0 16px;
            border-radius: 32px;
            border: 2px solid #059BCE;
            cursor: pointer;
            margin: 0;
        }
    }

    &-info-limit_description {
        //@include small-text;
        @include tr-text(12px, 400);
        font-family: 'OpenSansR';
        color: #707070;

        &:first-child {
            text-align: left !important;
        }

        &:last-child {
            text-align: right !important;
        }
    }
}

.travelata_input {
    //@include bold-input;
    color: var(--v-primary-base);
    // &::placeholder {
    //     color: var(--v-primary-base);
    // }

    color: var(--v-primary-base) !important;
    padding: 0 20px 0;

    & .v-icon {
        margin-top: 0 !important;
    }
    .v-input_slot {
        box-shadow: none !important;
    }

}

.container_info_limit {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: baseline;
}

.container {
    padding: 0 !important;
}


</style>
