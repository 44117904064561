<template>
    <body :style="{overflow: anketa.isOpenModal ? 'hidden' : ''}">
    <div class="travelata_outer">
        <div class="travelata_container">
            <header class="travelata_header">
                <div class="travelata_header_inner">
                    <img src="@/assets/img/travelata/travelata-logo.svg" alt="travelata-logo">
                    <div class="travelata_header_inner_divide"></div>
                    <img src="@/assets/img/travelata/paylate-logo.svg" alt="paylate-logo">
                </div>
            </header>

            <div class="travelata_content">
                <div class="travelata_content_inner">
                    <div class="travelata_content_inner_text">
                        <h2 class="travelata_content_uptitle">Для вас доступна услуга:</h2>
                        <h1 class="travelata_content_title">«Все включено»</h1>
                        <ul class="travelata_content_list">
                            <li class="travelata_content_list_item">получите обратно <span>до 100% стоимости тура</span></li>
                            <li class="travelata_content_list_item">оплачивайте равными платежами</li>
                            <li class="travelata_content_list_item">возвращайте всю сумму процентов</li>
                        </ul>
                        <h2
                            v-if="!urlParams || anketa.showCalculatorErrors.hasError"
                            class="travelata_content_subtitle travelata-massage-red">
                            {{anketa.showCalculatorErrors.hasError ? anketa.showCalculatorErrors.textError : 'Для подачи заявки перейдите по ссылке из смс'}}
                        </h2>
                    </div>
                    <TravelataTemplateSlider
                        v-if="urlParams && anketa.sliderParams && !anketa.showCalculatorErrors.hasError"
                        :reRenderComponent="trigger"
                    />
                </div>
            </div>
        </div>
        <BlockHTML4 v-if="anketa.actionInfo" />
        <BlockHTML5 />
    </div>

    </body>
</template>
<script>
import '@/assets/scss/style.scss';
import TravelataTemplateSlider from "@/components/TravelataTemplateSlider";
import BlockHTML4 from "@/components/html/BlockHTML4";
import BlockHTML5 from "@/components/html/BlockHTML5";
//   import BlockHTML6 from "@/components/html/BlockHTML6";
//   import SMSInfo from "@/components/SMSInfo";

export default {
    name: "TravelataMainPage",
    components: {
        TravelataTemplateSlider,
        //   BlockHTML6,
        BlockHTML4,
        BlockHTML5,
        //   SMSInfo,
    },
    data: () => ({
        trigger: false
    }),
    methods: {
        changeTrigger() {
            this.trigger = !this.trigger;
        },
    },
    computed: {
        connector() {
            return this.$root.connector;
        },
        anketa() {
            return this.$root.anketa;
        },
        urlParams: {
            get() {
                let urlParams = new URLSearchParams(window.location.search);
                return urlParams.has('s');
            },
        },
    },
    mounted(){
        console.log('url params',this.urlParams)
    }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_travelata-variables.scss";

.travelata{

    &_outer {
        background-color: #F3F7FA;
    }
    &_container {
        max-width: 1760px;
        width: 100%;
        margin: 0 auto;
    }
    &_header{
        width: 100%;
        height: 80px;
        background-color: #333333;
        border-radius: 4px;
        margin-bottom: 16px;

        &_inner {
            max-width: 1100px;
            width: 100%;
            margin: 0 auto;
            display: flex;
            //justify-content: space-between;
            gap: 15px;
            align-items: center;
            padding: 20px 0 0;

            & > img {
                @media (max-width: 400px) {
                    width: 100%;
                    height: auto;

                }
            }

            @media (max-width: 1150px) {
                padding: 20px 20px 0;
            }

            &_divide {
                height: 31px;
                width: 2px;
                border-radius: 2px;
                background-color: #fff;
            }
        }
    }

    &_content{
        background: url('../assets/img/travelata/travelata-bg.png');
        width: 100%;
        height: 730px;
        //border-radius: 8px;
        margin-bottom: 100px;
        background-size: cover;
        background-position-y: 100%;
        background-repeat: no-repeat;

        //&_subtitle {
        //    margin-top: 20px;
        //    @include tr-text(54px, 800, 56px);
        //    //font-family: OpenSansRegular;
        //
        //    @media (max-width: 1000px) {
        //        margin-bottom: 30px;
        //        color: #2F3441;
        //        text-align: center;
        //    }
        //
        //    @media (max-width: 450px) {
        //        text-align: left;
        //        // font-weight: 400;
        //        //@include vtb-form-text;
        //        font-size: 18px;
        //        line-height: 21.6px;
        //        letter-spacing: -0.6px;
        //    }
        //
        //    & > span {
        //        //@include vtb-form-text;
        //        line-height: 21.6px;
        //        letter-spacing: -0.6px;
        //        color: #ABABAB;
        //    }
        //}


        @media (max-width: 1150px) {
            padding: 0 20px;
        }
        @media (max-width: 1000px) {
            background: none;
            height: auto;
            margin-bottom: 0;
        }
        &_uptitle {
            @include tr-text(34px, 600, 56px);
            font-family: 'OpenSansSB';
            color: #333;

            @media (max-width: 600px) {
                // @include semibold-text-bigger;
                @include tr-text(20px, 600, 56px);
                font-family: 'OpenSansSB';
            }
            @media (max-width: 400px) {
                // @include semibold-text-bigger;
                @include tr-text(18px, 600, 40px);
                font-family: 'OpenSansSB';
            }
        }
        &_title {
            // font-weight: 500;
            // font-size: 50px;
            // line-height: 50px;
            // letter-spacing: -3px;
            @include tr-text(54px, 800, 56px);
            color: #059BCE;
            //margin-top: 15px;
            margin-bottom: 24px;
            font-family: 'OpenSansEB';

            @media (max-width: 1000px) {
                margin-top: 0;
                color: #EC933D;
            }

            @media (max-width: 600px) {
                // @include semibold-text-bigger;
                @include tr-text(40px, 800, 56px);

            }
            @media (max-width: 400px) {
                // @include semibold-text-bigger;
                @include tr-text(30px, 800, 40px);

            }
            & > .first_span {
                margin-left: 10px;
                font-weight: 300;
                font-size: 36px;
                line-height: 36px;
                letter-spacing: -3px;
                color: #ABABAB;

            }
        }
        &_inner {
            max-width: 1100px;
            width: 100%;
            margin: 0 auto;
            padding-top: 60px;
            display: flex;
            justify-content: space-between;
            height: 680px;

            @media (max-width: 1000px) {
                flex-direction: column;
                align-items: center;
                height: auto;
            }
            @media (max-width: 600px) {
                padding-top: 20px;
            }
            &_text {
                max-width: 576px;
                width: 100%;
                height: 300px;
                align-self: center;
                @media (max-width: 600px) {
                    height: unset;
                    margin-bottom: 20px;

                }

            }
        }

        &_list {
            &_item {
                @include tr-text(24px, 600, 42px);
                color: #333;
                font-family: 'OpenSansSB';

                @media (max-width: 600px) {
                    @include tr-text(18px, 400, 26px);
                    font-family: 'OpenSansR';
                }

                @media (max-width: 400px) {
                    @include tr-text(16px, 400, 26px);

                    span {
                        display: block;
                    }
                }
            }
        }
    }
    &-massage-red {
        color: red;
    }
}

</style>
