<template>
    <div :class='{active: disabledPacket}' class='additional-service mb-10'>
        <div>
            <div class='gradient-border'></div>

            <div
                v-if='openButtonMoreInsurance'
                @click='openButtonMoreInsurance = false'
                :class="$root.appType('travelata') || $root.appType('bankiros') || $root.appType('southwind') ? 'travelata_details' : ''"
                class='modal-info-expand'>Подробнее
            </div>

            <div
                v-else
                :class="[{active: disabledPacket}, $root.appType('travelata') || $root.appType('southwind') ? 'travelata_packet' : '']"
                class="additional-service-item d-flex justify-space-between align-center">
                <div
                    :class="{active: disabledPacket}"
                    class="additional-service-item_text"
                    >Пакет «<span :class='{"border-dashed pointer": !disabledPacket}' @click='packetTooltip = !packetTooltip'>Оптимальный</span>»
                </div>
                <p
                    v-if="!disabledPacket"
                    :class="addClass('additional-service-item_sum')"
                    class="additional-service-item_sum"
                    >{{ countingThousands }}
                    <span> &#8381;</span>
                </p>

                <p
                    v-else
                    @click='packetTooltip = !packetTooltip'
                    :class="addClass('additional-service_details')"
                    class='m-0 pointer font-weight-medium'
                    >Подробнее
                </p>

                <!-- задача Pl-189 -->
                <PacketTooltip
                    v-if='packetTooltip'
                    @choiseTarif='choiseTarif'
                    @disableTooltip='sureTooltip = true'
                    :packet='anketa.creditInformation'
                    v-model='packetTooltip'
                />
                <!--  -->
                <SureTooltip
                    v-if="sureTooltip"
                    @choiseTarif="choiseTarif"
                    @disableTooltip="disable"
                    :packet="anketa.creditInformation.insuranceData[2]"
                    v-model="sureTooltip"
                />
<!--                <SouthwindSureTooltip-->
<!--                    v-if="sureTooltip && $root.appType('southwind')"-->
<!--                    @choiseTarif="choiseTarif"-->
<!--                    @disableTooltip="disable"-->
<!--                    :packet="anketa.creditInformation.insuranceData[2]"-->
<!--                    v-model="sureTooltip"-->
<!--                />-->
            </div>
        </div>
    </div>
</template>

<script>
import PacketTooltip from "@/components/controls/PacketTooltip.vue";
import SureTooltip from "@/components/controls/SureTooltip.vue";
// import SouthwindSureTooltip from "@/components/southwind/controls/SouthwindSureTooltip.vue";

export default {
    components: {
        SureTooltip,
        PacketTooltip,
        // SouthwindSureTooltip
    },
    props: {
        loading: Boolean,
    },

    data: () => ({
        openButtonMoreInsurance: true,
        packetTooltip: false,
        sureTooltip: false,
        disabledPacket: false,
        fakeData: {
            amount: "3000.0",
            requestedSumm: "3000.0",
            currentDebtAmount: 0.0,
            creditDocuments: [
                {
                    documentName: "info_life",
                    url: "https://api-docs.paylate.ru/offer/cd543cec-0002-4535-bff3-e6818a4ebd6f",
                },
                {
                    documentName: "info_work",
                    url: "https://api-docs.paylate.ru/offer/cd543cec-0002-4535-bff3-e6818a4ebd6f",
                },
                {
                    documentName: "statement_services",
                    url: "https://api-docs.paylate.ru/offer/cd543cec-0002-4535-bff3-e6818a4ebd6f",
                },
                {
                    documentName: "statement_life",
                    url: "https://api-docs.paylate.ru/offer/cd543cec-0002-4535-bff3-e6818a4ebd6f",
                },
                {
                    documentName: "statement_work",
                    url: "https://api-docs.paylate.ru/offer/cd543cec-0002-4535-bff3-e6818a4ebd6f",
                },
                {
                    documentName: "statement_sms",
                    url: "https://api-docs.paylate.ru/offer/cd543cec-0002-4535-bff3-e6818a4ebd6f",
                },
                {
                    documentName: "insur_life",
                    url: "https://api-docs.paylate.ru/offer/cd543cec-0002-4535-bff3-e6818a4ebd6f",
                },
                {
                    documentName: "insur_work",
                    url: "https://api-docs.paylate.ru/offer/cd543cec-0002-4535-bff3-e6818a4ebd6f",
                },
                {
                    documentName: "consolidated",
                    url: "https://api-docs.paylate.ru/offer/cd543cec-0002-4535-bff3-e6818a4ebd6f",
                }
            ]
        }
    }),

    methods: {
        disable() {
            this.disabledPacket = true;
        },

        choiseTarif(id) {
            this.sendInsurancePackage(id).then(() => {
                if (id !== 0) {
                    for (let k = 0; k < this.$root.anketa.creditInformation.insuranceData.length; k++) {
                        if (this.$root.anketa.creditInformation.insuranceData[k].id === id) {
                            this.$root.anketa.creditInformation.insuranceData[k].insuranceSelected = true;
                        } else {
                            this.$root.anketa.creditInformation.insuranceData[k].insuranceSelected = false;
                        }
                    }
                } else {
                    for (let k = 0; k < this.$root.anketa.creditInformation.insuranceData.length; k++) {
                        this.$root.anketa.creditInformation.insuranceData[k].insuranceSelected = false;
                    }
                }
            });
        },

        sendInsurancePackage(id_tarif) {
            return this.$root.connector
                .isInsurancePackage(id_tarif)
                    .then((response) => {
                        if (response.success) {
                            this.disabledPacket = false;
                            console.log('additional services isInsurancePackage', response.info)
                            this.$root.anketa.creditInformation = response.info;
                        } else {
                            return false;
                        }
                    })
                    .catch((error) => {
                        this.errorAuthTextApi.status = true;
                        this.errorAuthTextApi.text = error;
                    });

        },
        addClass(cssClass){
            return `${cssClass}_${this.$root.state.type()}`
        }

    },

    computed: {
        anketa() {
            return this.$root.anketa;
        },

        countingThousands(){
            // anketa.creditInformation.insuranceData[2].cost
            return Number(this.anketa.creditInformation.insuranceData?.[2]?.cost).toLocaleString()
        },

    },
};
</script>

<style lang='scss' scoped>
@import '../assets/scss/_travelata-variables.scss';

.additional-service {
    margin-top: 20px;

    &.active {
        margin-top: 8px;
    }

    &_details {
        &_cash, &_auto {
            color: #EB3B66;
        }
        &_nordwind, &_southwind {
            color: #D6181E;
        }
        &_travelata {
            color: #059BCE;
        }
        &_bankiros {
            color: #388EC3;
        }
        &_vtb {
            color: #1976D2;
        }
    }
}

.additional-service-item {
    position: relative;
    border-radius: 12px;
    margin-top: 8px;
    padding: 12px;
    height: 49px;

    &_text {
        font-size: 18px;
        font-weight: 400;
        color: #707070;
        line-height: 24px;
        letter-spacing: -0.4px;

        &.active {
            color: #0A0A0D;
        }
    }

    &.active {
        margin-top: 20px;
        //background: #FFEEEB;
        border: 1px solid var(--v-primary-base);
    }
    &_sum {
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.4px;
        margin-bottom: 0;
        font-family: Roboto;

        &_cash, &_auto {
            color: #0A0A0D;
        }
        &_nordwind, &_southwind {
            color: #0A0A0D;
        }
        &_travelata {
            color: #0A0A0D;
        }
        &_bankiros {
            color: #333E50;
        }
        &_vtb {
            color: #2F3441;
        }

    }

}

.color-primary {
    color: var(--v-primary-base) !important;
}

.travelata {
    &_packet {
        @include tr-text(18px);
        font-family: 'OpenSansR' !important;
        color: #707070 !important;

        &.active {
            background-color: #fff;
            border: 1px solid #95CE5A;
        }
    }
    &_additional-services_packet_button {
        display: flex;
        justify-content: space-between;
        width: 100%;
        & > h3 {
            @include tr-text(18px);
            font-family: 'OpenSansR';
            color: #707070;
        }
        & > p {
            margin-bottom: 0;
            color: #333;
            & > span {
                font-family: Roboto;
            }
        }
    }

    &_blue_color {
        color: #059BCE;
    }

    &_details {
        &:after {
            content: url('../assets/img/travelata/travelata_shevron.png');

        }
    }

}

.southwind_border {
    color: #D6181E !important;
    border: none;
}
</style>
