<template>
    <div>
        <div v-if="chooseContent === 'lidgid'">
            <BankirosDeclined v-if="$root.appType('bankiros')"/>
            <SouthwindDeclined v-if="$root.appType(['southwind', 'nordwind'])"/>
            <TravelataDeclined v-if="$root.appType('travelata')"/>
            <CashDeclined v-if="$root.appType(['cash', 'auto'])"/>
            <VtbDeclined v-if="$root.appType('vtb')"/>
        </div>
        <DeclinedCityads v-if="chooseContent === 'cityads'"/>
        <div v-if="chooseContent === 'default'" class="main-option-for-app">
            <h1
                :class="$root.appType('travelata') ? 'travelata_declined' : ''"
                >Заявка отклонена</h1>
            <p class="declined-default_subtitle mt-2">
                К сожалению, вам отказано в предоставлении сервиса
            </p>
            <img class="declined-default_img" src="../../assets/img/pngegg.png">
        </div>

    </div>
</template>

<script>
import BankirosDeclined from "@/components/bankiros/modal/Declined.vue";
import SouthwindDeclined from "@/components/southwind/controls/Modals/SouthwindDeclined.vue";
import TravelataDeclined from "@/components/travelata/TravelataDeclined.vue";
import CashDeclined from "@/components/cash/CashDeclined.vue";
import VtbDeclined from "@/components/vtb/VtbDeclined.vue";
import DeclinedCityads from "@/components/modal/DeclinedCityads.vue";

export default {
    name: "Declined",
    components: {
        BankirosDeclined,
        SouthwindDeclined,
        TravelataDeclined,
        CashDeclined,
        VtbDeclined,
        DeclinedCityads
    },
    computed: {
        hasUtmSource(){
            let url = location.search.split('&');
            let utmSource = url.find(el => el.includes('utm_source'))
            return !!utmSource;
        },
        getUtmSource() {
            let url = location.search.split('&');
            let utmSource = url.find(el => el.includes('utm_source'))?.split('=')[1];
            return utmSource
        },
        isCityads(){
            return this.getUtmSource === 'cityads';
        },
        chooseContent(){
            let type = null;
            if(this.hasUtmSource){
                if(this.getUtmSource === 'lg'){
                    type = 'lidgid'
                }else if(this.getUtmSource === 'cityads'){
                    type = 'cityads'
                }else {
                    type = 'default'
                }
            }else {
                type = 'default'
            }
            return type;
        },
        getMainColor(){
            let mainColor = null;
            let type = this.$root.state.type()
            let colors = {
                auto: '#EB3B66',
                cash: '#EB3B66',
                bankiros: '#388EC3',
                travelata: '#EC933D',
                nordwind: '#D6181E',
                southwind: '#D6181E',
                vtb: '#1976D2'
            };
            for(let color in colors){
                if(type === color){
                    mainColor = colors[color];
                }
            }
            return mainColor
        },
        getParams(){
            let params = location.search.split('&');
            let findParams = params.find(param => param === 'utm_source=lg');
            console.log('url', params);
            return findParams;
        }

    },
    methods: {
        handleBackButton() {
            this.$root.mindbox("Клиент использовал лидгид-камбекер Кэш");
            window.location.href = 'https://money-dam.ru/back';
            // location.replace = 'https://money-dam.ru/back'
        }

    },

    mounted() {

        // history.pushState(null, '', location.href);

        // window.addEventListener('popstate', this.handleBackButton);
        if(this.getParams){

            history.pushState(null, '', location.href);
            window.addEventListener('popstate', this.handleBackButton);
        }

        this.$root[`metrika${this.$root.addYandexMetricGoals()}`].reachGoal("reject");

    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_travelata-variables.scss';

.travelata {
    &_declined {
        @include tr-text(24px, 800, 28px);
        font-family: 'OpenSansEB';
        color: #333;
    }
}

.declined-default {
    &_subtitle {
        font-family: Roboto, sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.4px;
        color: v-bind(getMainColor);
        text-align: left;
    }
    &_img {
        width: 72px;
        height: 72px;
    }
}
</style>
