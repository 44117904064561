<template>
    <div>
        <div class="main-option-for-app text-left">
            <h1 class="forms_title">Заявка отклонена</h1>

            <div class="bankiros_declined"><p class="mt-3 mb-4 blue--text">К сожалению, вам отказано в предоставлении сервиса</p>
                <img class="bankiros_declined_img" src="@/assets/img/pngegg (1).png" alt="sad_emoji">
            </div>
<!--            <p class="status-img mt-8">-->
<!--                <img src="@/assets/img/bankiros/sad.png">-->
<!--            </p>-->

            <p>
                <span class="grey2--text">
                    Но вы можете получить деньги у наших партнёров.
                </span>
                Уровень одобрения по вашей заявке будет составлять
                 <span class="blue--text">96%</span>
            </p>
<!--            <a class="bankiros_declined_link" href="https://money-dam.ru/?lg_uid=eb4d2722-4c6a-412a-9128-cef03debd422&lg_ut=1728389729">-->
<!--                <MainButton :hasMindboxGoal="true">-->
<!--                    Одобренные предложения-->
<!--                </MainButton>-->
<!--            </a>-->
            <MainButton @click="redirectToPartners">Одобренные предложения</MainButton>
        </div>
    </div>
</template>

<script>

import MainButton from "@/components/global/MainButton.vue";

export default {
    name: "BankirosDeclined",
    components: {MainButton},
    methods: {
        redirectToPartners(){
            this.$root.mindbox("Клиент перешел в лидгид со страницы отказа Кэш");
            window.location.replace("https://money-dam.ru/?lg_uid=eb4d2722-4c6a-412a-9128-cef03debd422&lg_ut=1728389729");
        }
    },

    mounted() {
        this.$root[`metrika${this.$root.addYandexMetricGoals()}`].reachGoal("reject");
        this.$root.mindbox("Отказ по заявке Кэш");
    }
}
</script>

<style lang="scss" scoped>
.bankiros {
    &_declined {
        display: flex;
        gap: 10px;
        align-items: center;
        &_link {
            text-decoration: none !important;
        }

        &_img {
            width: 16px;
            height: 16px;
            margin-bottom: 5px;
        }
    }
}
</style>
