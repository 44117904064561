<template>
    <body :style="{overflow: anketa.isOpenModal ? 'hidden' : ''}">
      <div class="vtb_outer">
        <div class="vtb_container">
          <header class="vtb_header">
            <div class="vtb_header_inner">
              <!-- <img src="../assets/img/nordwind/Nordwind.svg" alt="nordwind logo"> -->
              <img src="../assets/img/nordwind/Paylate.svg" alt="paylate logo">
              <a class="vtb_header_inner_telephone" href="tel:+88007700300">8 (800) 770-03-00</a>
            </div>
          </header>

          <div class="vtb_content">
              <div class="vtb_content_inner">
                <div class="vtb_content_inner_text">
                  <h1 class="vtb_content_title">
                    Вам доступен кредит<span class="first_span">*</span> наличными на любые цели!
                  </h1>
                  <h2 class="vtb_content_subtitle">
                    Перечислим деньги на карту за 2 минуты <span>**</span>
                  </h2>
                  <h2
                    v-if="!urlParams || anketa.showCalculatorErrors.hasError"
                    class="vtb_content_subtitle vtb-massage-red">
                    {{anketa.showCalculatorErrors.hasError ? anketa.showCalculatorErrors.textError : 'Для подачи заявки перейдите по ссылке из смс'}}
                  </h2>
                </div>
                <VtbTemplateSlider
                  v-if="urlParams && anketa.sliderParams"
                  :reRenderComponent="trigger"
                />
              </div>
          </div>
        </div>
        <BlockHTML4 v-if="anketa.actionInfo" />
        <BlockHTML5 />
      </div>

    </body>
  </template>
  <script>
  import '@/assets/scss/style.scss';
  import VtbTemplateSlider from "@/components/VtbTemplateSlider";
  import BlockHTML4 from "@/components/html/BlockHTML4";
  import BlockHTML5 from "@/components/html/BlockHTML5";
//   import BlockHTML6 from "@/components/html/BlockHTML6";
//   import SMSInfo from "@/components/SMSInfo";

  export default {
    name: "VtbMainPage",
    components: {
      VtbTemplateSlider,
    //   BlockHTML6,
      BlockHTML4,
      BlockHTML5,
    //   SMSInfo,
    },
    data: () => ({
      trigger: false,
      showErrors: false
    }),
      watch:{
          'anketa.showCalculatorErrors.hasError'(newVal, oldVal){
              if(newVal !== oldVal){
                  this.showErrors = true;
              }
          }
      },
    methods: {
      changeTrigger() {
        this.trigger = !this.trigger;
      },
    },
    computed: {
      connector() {
        return this.$root.connector;
      },
      anketa() {
        return this.$root.anketa;
      },
      urlParams: {
        get() {
          let urlParams = new URLSearchParams(window.location.search);
          return urlParams.has('s');
        },
      },
    },
    mounted(){
      console.log('url params',this.urlParams)
    }
  };
  </script>

  <style lang="scss" scoped>
  @import "@/assets/scss/_vtb-variables.scss";

  .vtb {

    &_outer {
      background-color: #F3F7FA;
    }
    &_container {
      max-width: 1760px;
      width: 100%;
      margin: 0 auto;
    }
    &_header{
      width: 100%;
      height: 80px;
      background-color: #232229;
      border-radius: 8px;
      margin-bottom: 20px;
      &_inner {
        max-width: 1100px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0 0;

        @media (max-width: 1150px) {
          padding: 20px 20px 0;
        }

        &_telephone {
          font-family: VtbMedium;
          color: #fff;
          text-decoration: none;
          font-weight: 500;
          font-size: 18px;
          line-height: 18px;
          letter-spacing: -0.4px;
        }
      }
    }

    &_content{
      background: url('../assets/img/vtb/vtb-bg.png');
      width: 100%;
      height: 815px;
      border-radius: 8px;
      margin-bottom: 100px;

      &_subtitle {
        margin-top: 20px;
        @include medium-text;

        @media (max-width: 1000px) {
          margin-bottom: 30px;
          color: #2F3441;
          text-align: center;
        }

        @media (max-width: 450px) {
          text-align: left;
          // font-weight: 400;
          @include vtb-form-text;
          font-size: 18px;
          line-height: 21.6px;
          letter-spacing: -0.6px;
        }

        & > span {
          @include vtb-form-text;
          line-height: 21.6px;
          letter-spacing: -0.6px;
          color: #ABABAB;
        }
      }


      @media (max-width: 1150px) {
        padding: 0 20px;
      }
      @media (max-width: 1000px) {
        background: none;
      }

      &_title {
        // font-weight: 500;
        // font-size: 50px;
        // line-height: 50px;
        // letter-spacing: -3px;
        @include big-text;
        margin-top: 140px;
        @media (max-width: 1000px) {
          margin-top: 0;
          color: #0A2896;
        }

        @media (max-width: 600px) {
          // @include semibold-text-bigger;
        }
        & > .first_span {
          margin-left: 10px;
          font-weight: 300;
          font-size: 36px;
          line-height: 36px;
          letter-spacing: -3px;
          color: #ABABAB;

        }
      }


      &_inner {
        max-width: 1100px;
        width: 100%;
        margin: 0 auto;
        padding-top: 50px;
        display: flex;
        justify-content: space-between;

        @media (max-width: 1000px) {
          flex-direction: column;
          align-items: center;
        }
        @media (max-width: 500px) {
          padding-top: 20px;
        }
        &_text {

        }
      }
    }
    &-massage-red {
      color: red;
    }
  }

  </style>
