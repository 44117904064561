<template>
    <body class="main-page" :style="{overflow: anketa.isOpenModal ? 'hidden' : ''}">
    <TemplateSlider
        v-if="anketa.sliderParams"
        :reRenderComponent="trigger"
        >
        <div class="announce-description">
            <img src="@/assets/img/auto/car.png" alt="car">
            <h1 class="mt-0">
                Вам доступен продукт "Деньги под залог автомобиля"
            </h1>

            <p class="announce-description__auto_desc">Авто и ПТС остаются у вас.
                Работаем по всей России.</p>
            <div v-if="showErrors && urlParams" class="main-page_errors">
                <p>{{anketa.showCalculatorErrors.textError}}</p>
            </div>
            <div v-show="!urlParams" class="main-page_errors">
                <p>Для подачи заявки перейдите по ссылке из смс</p>
            </div>
        </div>

    </TemplateSlider>
    <SMSInfo v-else/>
    <GetMoney />
    <BlockHTML4 v-if="anketa.actionInfo" />
    <Requirements/>
    <Questions/>
    </body>
</template>
<script>
import '@/assets/scss/style.scss';
import TemplateSlider from "@/components/TemplateSlider";
import BlockHTML4 from "@/components/html/BlockHTML4";
import SMSInfo from "@/components/SMSInfo";
import Requirements from "@/components/auto/Requirements.vue";
import Questions from "@/components/auto/Questions.vue";
import GetMoney from "@/components/auto/GetMoney.vue";

export default {
    name: "MainPage",
    components: {
        GetMoney,
        Questions,
        Requirements,
        TemplateSlider,
        BlockHTML4,
        SMSInfo,
    },
    data: () => ({
        trigger: false,
        showErrors: false
    }),
    watch:{
        'anketa.showCalculatorErrors.hasError'(newVal, oldVal){
            if(newVal !== oldVal){
                this.showErrors = true;
            }
        }
    },
    methods: {
        changeTrigger() {
            this.trigger = !this.trigger;
        },
    },
    computed: {
        connector() {
            return this.$root.connector;
        },
        anketa() {
            return this.$root.anketa;
        },
        urlParams: {
            get() {
                let urlParams = new URLSearchParams(window.location.search);
                return urlParams.has('s');
            },
        },
        // hasParams(){
        //
        // }
    },

    mounted(){
        console.log('urlParams', this.urlParams, !this.urlParams)
    }
};
</script>

<style lang="scss" scoped>
.announce-description__auto_desc {
    max-width: 300px;
    line-height: 1.3em !important;
}

@media (max-width: 1124px) {
    .announce-description__auto_desc {
        text-align: center;
        font-size: 16px !important;
        margin: 24px auto !important;
    }
}

// @import '../assets/scss/style.scss';
.announce-description > .main-page_errors > p {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    color: red !important;
}
</style>
