<template>
    <body :style="{overflow: anketa.isOpenModal ? 'hidden' : ''}">
    <div class="southwind_container">
        <header class="southwind_header">
            <div class="southwind_header_inner">
                <img src="../assets/img/southwind/southwind_logo.svg" alt="nordwind logo">
                <div class="southwind_header_inner_separator"></div>
                <img src="../assets/img/nordwind/Paylate.svg" alt="paylate logo">
            </div>
        </header>
        <div class="southwind_container_outer">
            <div class="southwind_container_inner">
                <div class="southwind_container_info">
                    <h2 class="southwind_container_info_uptitle">Вам доступен продукт:</h2>
                    <h1 class="southwind_container_info_title">Наличные в поездку</h1>
<!--                    <ul class="southwind_container_info_list">-->
<!--                        <li class="southwind_container_info_item">вернём всю стоимость авиабилетов</li>-->
<!--                        <li class="southwind_container_info_item">оплачивайте равными платежами</li>-->
<!--                        <li class="southwind_container_info_item">получайте дополнительный кешбэк</li>-->
<!--                    </ul>-->
                    <p class="southwind_container_info_subtitle_first">Перечислим деньги на карту за 2 минуты<span>**</span></p>
                    <h3
                        v-if="!checkingParams || anketa.showCalculatorErrors.hasError"
                        class="southwind_container_info_subtitle"
                        >{{anketa.showCalculatorErrors.hasError ? anketa.showCalculatorErrors.textError : 'Для подачи заявки перейдите по ссылке из смс'}}
                    </h3>
                </div>
                <SouthwindTemplateSlider
                    v-if="urlParams && anketa.sliderParams"
                    :reRenderComponent="trigger"
                />
            </div>
        </div>
    </div>
    <BlockHTML4 v-if="anketa.actionInfo"/>
<!--    <FormHTML />-->
    <BlockHTML5 />

    </body>
</template>
<script>
// import '@/assets/scss/nordwind_styles/nordwind_style.scss';
import '@/assets/scss/style.scss';
import BlockHTML4 from "@/components/html/BlockHTML4";
import BlockHTML5 from "@/components/html/BlockHTML5";
// import FormHTML from "@/components/modal/FormHTML";
import SouthwindTemplateSlider from "@/components/SouthwindTemplateSlider.vue";

export default {
    name: "SouthwindMainPage",
    components: {
        BlockHTML4,
        BlockHTML5,
        // FormHTML,
        SouthwindTemplateSlider
    },
    data: () => ({
        trigger: false,
        color: 'red',
        showErrors: false
    }),
    watch:{
        'anketa.showCalculatorErrors.hasError'(newVal, oldVal){
            if(newVal !== oldVal){
                this.showErrors = true;
            }
        }
    },
    methods: {
        changeTrigger() {
            this.trigger = !this.trigger;
        },
    },
    computed: {
        connector() {
            return this.$root.connector;
        },
        anketa() {
            return this.$root.anketa;
        },
        urlParams: {
            get() {
                let urlParams = new URLSearchParams(window.location.search);
                return urlParams.has('s');
            },
        },
        isNordwind(){
            return this.$root.state.isNordwind()
        },
        checkingParams(){
            let urlParams = new URLSearchParams(window.location.search);
            console.log('urlParams', urlParams.get('s'))
            return urlParams.get('s');
        }
    },
};
</script>

<style lang="scss" scoped>
.southwind {

    &_header {
        background-color: #AB292D;
        background-color: var(--v-primary-base);
        height: 80px;
        max-width: 1760px;
        margin: 0 auto 30px;
        width: 100%;
        border-radius: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;

        &_inner {
            max-width: 1100px;
            width: 100%;
            display: flex;
            align-items: center;
            gap: 15px;
            padding: 0 20px;

            @media (max-width: 450px) {
                max-width: 400px;
            }

            & >img {
                @media (max-width: 400px) {
                    width: 45%;
                    height: auto;
                }
            }

            &_separator {
                height: 31px;
                width: 2px;
                border-radius: 2px;
                background-color: #fff;

            }
        }
    }

    &_container {
        background-color: #fff;
        padding: 30px 0 50px;
        border-radius: 24px;

        &_outer {
            background: url('../assets/img/nordwind/bg_first.png');
            background-position-x: center;
            background-repeat: no-repeat;
            padding-top: 30px;
            border-radius: 24px;
            height: 720px;

            @media (max-width: 950px){
                padding-top: 0;
                height: unset;
                background: none;

            }
        }
        &_inner {
            max-width: 1100px;
            width: 100%;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;

            @media (max-width: 1200px) {
                padding: 0 20px;
            }

            @media (max-width: 950px){
                flex-direction: column;
                align-items: center;
            }
        }
        &_info {
            //max-width: 500px;
            margin-top: 90px;
            width: 100%;

            @media (max-width: 950px){
                margin-top: 0;
            }

            &_uptitle {
                color: #0A0A0D;
                font-family: Roboto;
                font-weight: 500;
                font-size: 34px;
                line-height: 56px;
                letter-spacing: -0.4px;

                @media (max-width: 1000px) {
                    font-size: 1.8rem;
                }
                @media (max-width: 950px){
                    font-size: 20px;
                    text-align: center;
                }


            }

            &_title {
                color: #D6181E;
                font-family: NerisBlack;
                font-size: 54px;
                line-height: 56px;
                letter-spacing: -1px;
                margin-bottom: 24px;

                &::before{
                    content: '\00AB';
                }

                &::after {
                    content: '\00BB';
                }

                @media (max-width: 1000px) {
                    font-size: 3rem;
                }

                @media (max-width: 950px){
                    font-size: 30px;
                    line-height: 30px;
                    text-align: center;
                    margin-bottom: 10px;
                }
            }

            &_subtitle {
                color: #D6181E;
                font-family: Neris;
                font-size: 24px;
                line-height: 30px;
                letter-spacing: 1px;

                &_first {
                    font-family: Roboto;
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 36px;
                    letter-spacing: -0.4px;
                    color: #0A0A0D;

                    & > span {
                        color: #BFBFC0;
                    }
                }
            }

            &_list {
                color: #0A0A0D;
                font-family: Roboto;
                font-weight: 400;
                font-size: 24px;
                line-height: 36px;

                @media (max-width: 950px){
                    font-size: 18px;
                    line-height: 24px;
                    margin-bottom: 20px;
                }

            }
        }
    }
}
</style>
