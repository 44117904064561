<template>
    <div :class="{'mb-16': !urlParams}" class="bankiros-hero">
        <div class="container">
            <div class="bankiros-hero__body d-flex justify-space-between">
                <div>
                    <h1 class="bankiros-hero__title">
                        {{urlParams ? 'Вам доступен кредит* наличными на любые цели!' : 'Для подачи заявки перейдите по ссылке из смс'}}
                    </h1>
                    <p v-if="urlParams" class="mt-4 mb-0 is-size-5">Перечислим деньги на карту за 2 минуты**</p>
<!--                    <p v-if="urlParams" class="mt-4 mb-0 is-size-5 main-page_errors">Что-то пошло не так... Попробуйте обновить страницу.</p>-->
                    <div v-if="showErrors" class="main-page_errors">
                        <p>{{anketa.showCalculatorErrors.textError}}</p>
                    </div>
                </div>

                <div class="bankiros-hero__slider">
                    <BankirosTemplateSlider
                        v-if="urlParams && anketa.sliderParams && !showErrors"
                        class="bankiros-hero__slider_item"
                    />

                    <img class="bankiros-hero__slider_img" src="@/assets/img/bankiros/hero-bg.png">
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import BankirosTemplateSlider from "@/components/bankiros/slider/TemplateSlider.vue";

export default {
    name: "BankirosHero",
    components: {BankirosTemplateSlider},
    data(){
        return {
            showErrors: false
        }
    },
    watch:{
        'anketa.showCalculatorErrors.hasError'(newVal, oldVal){
            if(newVal !== oldVal){
                this.showErrors = true;
            }
        }
    },
    computed: {
        urlParams: {
            get() {
                let urlParams = new URLSearchParams(window.location.search);
                return urlParams.has('s');
            },
        },
        anketa(){
            return this.$root.anketa;
        }
    },
}
</script>

<style lang="scss" scoped>
.bankiros-hero {
    padding: 60px 0;
    background: var(--v-grey-base);
    min-height: 490px;

    &__body {
        gap: 32px;
    }

    &__title {
        font-size: 60px;
        color: var(--v-primary-base);
        max-width: 600px;
        line-height: 1.2em;
    }

    &__slider {
        position: relative;
        max-width: 380px;
        width: 100%;


        &_item {
            position: relative;
            z-index: 1;
        }

        &_img {
            position: absolute;
            right: 0;
            top: 0;
            transform: translate(40%, -10%);

            //z-index: -1;
        }
    }

    @media (max-width: 920px) {
        &__title {
            font-size: 40px;
        }
    }

    @media (max-width: 768px) {
        &__body {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 20px;

            h1 {
                text-align: center;
            }

            .is-size-5 {
                text-align: center;
            }
        }
    }

    @media (max-width: 600px) {
        padding: 42px 0;

        &__slider {
            &_img {
                display: none;
            }
        }
    }

    @media (max-width: 520px) {
        h1 {
            font-size: 24px;
        }
    }
}

.main-page_errors {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    color: red;
}
</style>
