<template>
    <div
        class="sure-tooltip"
        >
        <div class="d-flex align-center justify-space-between">
            <h4
                :class="addClass('sure-tooltip_title')"
                class="sure-tooltip_title"
                >Вы уверены?</h4>

            <v-btn fab elevation="1" class="sure-tooltip_close-button">
                <img src="../../assets/img/close.svg" alt="logotype" />
            </v-btn>
        </div>

        <p
            class="sure-tooltip_text mb-2 mt-4 text-left"
            >Cтрахование помогает обезопасить себя от возможных финансовых потерь
            и непредвиденных ситуаций: смерть, потеря дохода, нетрудоспособность
            и т.д.
        </p>
        <h4
            class="sure-tooltip_subtitle text-left mt-3"
            >Рекомендуем подключить услугу!
        </h4>

        <p v-if="errorAuthTextApi" class="my-2 error--text">
            {{ errorAuthTextApi }}
        </p>

        <div class="sure-tooltip_buttons">
            <v-btn
                :loading="loading"
                @click="disable"
                class="sure-tooltip_buttons_button sure-tooltip_off-button text-center">Отключить
            </v-btn>
            <v-btn
                @click="$emit('input')"
                class="sure-tooltip_buttons_button sure-tooltip_next-button"
                >Далее
            </v-btn>
        </div>
    </div>
</template>

<script>

export default {
    data: () => ({
        errorAuthTextApi: "",
        exceptionsTypes: [],
        loading: false,
    }),
    methods: {
        disable() {
            this.loading = true;
            this.$root.connector
                .isInsurancePackage(0)
                .then((response) => {

                    if (response.success) {
                        this.$emit("input");
                        this.$emit("disableTooltip");
                        this.$root.anketa.creditInformation = response.info;
                    } else {
                        this.errorAuthTextApi = response.message;
                    }
                })
                .catch((error) => {
                    this.errorAuthTextApi = error;
                })
                .finally(() => (this.loading = false));
        },

        addClass(cssClass){
            let searchingClass = null
            // console.log('addClass', this.exceptionsTypes.find(this.$root.state.type()))
            if(this.exceptionsTypes.find(el => el === this.$root.state.type())) {
                searchingClass = `${cssClass}`
            }else {
                searchingClass = `${cssClass}_${this.$root.state.type()}`
            }
            return searchingClass
        },

    },

    computed: {
        chooseBorder(){
            let cssClass = null
            if(this.$root.appType('vtb')){
                cssClass = 'vtb-border-button'
            }else if(this.$root.appType('nordwind')) {
                cssClass = 'nordwind-border-button'
            }else if(this.$root.appType('cash') || this.$root.appType('auto')) {
                cssClass = 'cash-border-button'
            }else if(this.$root.appType('bankiros')) {
                cssClass = 'rectangle-button'
            }
            return cssClass
        },

    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_travelata-variables.scss';

.sure-tooltip {
    position: absolute;
    padding: 24px;
    border: 1px solid #e9e9e9;
    border-radius: 12px;
    background-color: #fff;
    bottom: 60px;
    z-index: 100;

    &_title {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.4px;
        color: #0A0A0D;

        &_cash, &_nordwind, &_southwind, &_auto {
            font-family: Neris;
        }

        &_vtb {
            font-family: Roboto; // Уточнить
        }
        &_travelata {
            font-family: Roboto; // Уточнить
        }
        &_bankiros {
            font-family: Roboto; // Уточнить
        }

    }

    &_close-button {
        position: relative;
        display: block;
        cursor: pointer;
        margin: 0;
        width: 24px;
        height: 24px;
        text-align: center;
        align-items: center;
        border-radius: 50%;
        color: #E7E7E7;
        box-shadow: none !important;

    }

    &_text {
        font-family: Roboto;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.4px;
        color: #6C6C6E;
    }

    &_subtitle {
        font-family: Roboto;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.4px;
        color: #0A0A0D;
    }

    &_buttons {
        display: flex;
        justify-content: flex-end;
        gap: 20px;
        margin-top: 65px;

        &_button {
            text-transform: capitalize;
            font-family: Roboto;
            font-weight: 900;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.4px;
        }
    }
    &_next-button {
        border-radius: 8px;
        max-width: 128px;
        width: 100%;
        height: 27px !important;
        color: #fff;
        background-color: var(--v-primary-base) !important;
    }

    &_off-button {
        max-width: 98px;
        width: 100%;
        height: 27px !important;
        color: #ABABAB;
        border: 1px solid #848485;
        border-radius: 10px;
        background-color: transparent !important;
    }

}


//.tooltip {
//    position: absolute;
//    padding: 16px;
//    border: 1px solid #e9e9e9;
//    border-radius: 12px;
//    background-color: #fff;
//    bottom: 60px;
//    z-index: 100;
//
//    //@media (max-width: 1250px) {
//    //    margin-bottom: 40px;
//    //}
//    //@media (max-width: 960px) {
//    //    margin-bottom: 80px;
//    //}
//    //@media (max-width: 600px) {
//    //    margin-bottom: 100px;
//    //}
//    //@media (max-width: 380px) {
//    //    margin-bottom: 140px;
//    //}
//    //@media (max-width: 350px) {
//    //    margin-bottom: 230px;
//    //}
//
//    .modal-icon {
//        width: 24px !important;
//        height: 24px !important;
//    }
//}
//
//.cash-border-button, .auto-border-button {
//    height: 42px !important;
//}
//.nordwind-border-button {
//    height: 42px !important;
//}
//
//.travelata_sure-tooltip {
//    position: absolute;
//    padding: 16px;
//    border: 1px solid #e9e9e9;
//    border-radius: 12px;
//    background-color: #fff;
//    bottom: 60px;
//    z-index: 100;
//    display: flex;
//    flex-direction: column;
//
//    &_title {
//        color: #333 !important;
//    }
//    .modal-icon {
//        width: 24px !important;
//        height: 24px !important;
//    }
//
//    &_text {
//        @include tr-text(14px);
//        font-family: 'OpenSansR';
//        color: #707070;
//    }
//
//    &_subtitle {
//        @include tr-text();
//        color: #333;
//        font-family: 'OpenSansR';
//    }
//
//    &_buttons {
//        display: flex;
//        flex-direction: row-reverse;
//        align-items: center;
//        max-width: 250px;
//        gap: 10px;
//        align-self: end;
//        margin-top: 20px;
//
//    }
//
//    &_next-button {
//        background-color: #EC933D !important;
//        max-width: 128px;
//        margin: 0 !important;
//        //position: absolute;
//        //bottom: 0;
//        //right: 0;
//        color: #fff !important;
//        @include tr-text(12px, 800, 16px);
//        font-family: 'OpenSansEB';
//        border-radius: 8px;
//    }
//
//    &_pointer {
//        @include tr-text(12px, 400, 16px);
//        color: #707070;
//        font-family: 'OpenSansR';
//    }
//}
</style>

<style lang="scss">
    .sure-tooltip_off-button .v-btn__loader {
        & .v-progress-circular {
            height: 15px !important;
            width: 15px !important;

        }
    }
</style>
